import { processQuillContent } from 'shared/helpers/quillHelper';

import { MEDIA_CAPTION_URL_CHECK } from './consts/consts';
import { IObjProps } from './consts/types';
import { getEllipsisedText } from './helpers';

export enum NodeType {
	SEND_MSG = 'SEND_MSG',
	SEND_MSG_OPTNS = 'SEND_MSG_OPTNS',
	START = 'START',
	DECISION_NODE = 'EVAL_LOGIC',
	SEND_DOCUMENT = 'SEND_DOCUMENT',
	SEND_CAROUSEL = 'SEND_CAROUSEL',
	LIVE_CHAT_CATEGORY = 'LIVE_CHAT_CATEGORY',
	ACTN_NODE = 'ACTN_NODE',
	TEMPLATE_MESSAGE = 'TEMPLATE_MESSAGE',
	CONVERSATION_STATUS_NODE = 'CONVERSATION_STATUS_NODE',
}

export function tagsNotAllowed(t: any, input: any, required: boolean) {
	let flag = true;

	const reg = /<!DOCTYPE html>|<\/?[a-zA-Z][\s\S]*>|(\&-(?:[\w\d]+|#\d+|#x[a-f\d]+);)/;

	if (required && (!input || (typeof input === 'string' && input.trim() === ''))) {
		return t('errors:requiredErrorMessage');
	}

	if (typeof input === 'string' && input && input.match(reg) !== null) {
		flag = false;

		return t('errors:noHtmlTagsAllowed');
	}

	return flag;
}

export function htmlTagsAllowed(t: any, input: any, required: boolean) {
	const inputName = input;
	let flag = true;
	const re =
		/<(?!b|\/b|(\bstrong\b)|(\/\bstrong\b)|a|\/a|i|\/i|(em)|(\/em)|\bs\b|\/\bs\b|u|\/u|(div)|(\/div)|p|\/p|br)[^>]{0,}>|<>|<$|\\w*<\\w+$|^>[a-z]+$|<(\/b|i|\/i|(\bstrong\b)|(\/\bstrong\b)|(em)|(\/em)|\bs\b|\/\bs\b|u|\/u|(div)|(\/div)|p|\/p|br)[^(>|\\s)][^>]{0,}>|<(b)[^(r|>|\\s)]{1}[^>]{0,}>|^\\w*>\\w*<\\w*/gi;

	if (required && (!inputName || inputName.trim() === '')) {
		return t('errors:requiredErrorMessage');
	}

	if (inputName && inputName.match(re) !== null) {
		flag = false;

		return t('errors:htmlTagsAllowed');
	}

	return flag;
}

export function checkSliderMaxStep(t: any, input: any, max: number, min: number, stepValue: number) {
	let flag = true;

	const diff = max - min;
	const minValue = Math.ceil(diff / 25);
	const maxValue = Math.ceil(diff / 3);
	if (input < minValue || input > maxValue) {
		flag = false;

		return t('errors:sliderMaxStep', { minValue, maxValue });
	}

	return flag;
}

export const replaceVariablesInMsg = function (msg: any, generatedvariables: any) {
	// generated variables is the response object from path variables api
	msg = msg && msg.toString();
	if (msg) {
		// appending 'temp_attr_id=' string to attribute id and then removing it after replacement of each key to avoid key replacement more than once.
		for (const key in generatedvariables) {
			msg = msg.replaceAll(`{{${key}}}`, `{{temp_attr_id=${generatedvariables[key]}}}`);
		}
		msg = convertRemainingObjectKeys(msg, generatedvariables);
		msg = msg.replaceAll(new RegExp('{{temp_attr_id=', 'g'), '{{');
	}

	return msg;
};

// convertRemainingObjectKeys - to replace variables in nested objects
const convertRemainingObjectKeys = (msg: string, generatedVariables: Array<IObjProps>) => {
	for (const key in generatedVariables) {
		if (msg.includes(`{{${key}.`)) {
			msg = msg.replaceAll(`{{${key}.`, `{{temp_attr_id=${generatedVariables[key]}.`);
		}
	}

	return msg;
};

const replaceRemainingObjectVariables = (msg: string, generatedVariables: Array<IObjProps>) => {
	for (const key in generatedVariables) {
		if (msg.includes(`{{${generatedVariables[key]}`)) {
			msg = msg.replaceAll(new RegExp(`{{${generatedVariables[key]}`, 'g'), `{{temp_attr_name=${key}`);
		}
	}

	return msg;
};

export const replaceKeyWithVariableName = function (msg: any, generatedvariables: any, wrapInPTags = false) {
	if (msg) {
		// appending 'temp_attr_name:' string to attribute name and then removing it after replacement of each id to avoid replacement more than once.
		for (const key in generatedvariables) {
			msg = msg.replace(new RegExp(`{{${generatedvariables[key]}}}`, 'g'), `{{temp_attr_name=${key}}}`);
		}
		msg = replaceRemainingObjectVariables(msg, generatedvariables);
		msg = msg.replace(new RegExp('temp_attr_name=', 'g'), '');
		msg = msg.replace(/\{{attr_(.*?)\}}/g, ' ');
		msg = msg.replace(/\{{var_(.*?)\}}/g, ' ');
		msg = wrapInPTags ? `<p>${msg}</p>` : msg;
	}

	return msg;
};

export const getBodyValue = (headerType: string, bodyParam: any, body: any, applicationUrlEncoded: string) => {
	let payloadAttr = '';
	let parsedBodyData = '';
	if (headerType === applicationUrlEncoded) {
		bodyParam?.forEach((param: any, index: any) => {
			if (param.key !== '') {
				if (payloadAttr !== '') {
					payloadAttr += '&';
				}
				payloadAttr += `${param.key}=${param.value}`;
			}
		});
		parsedBodyData = payloadAttr;
	} else {
		parsedBodyData = body || '';
	}

	return parsedBodyData;
};

export const getParamValue = (bodyParam: IObjProps) => {
	let payloadAttr = '';
	bodyParam?.forEach((param: { key: string; value: string }) => {
		if (param.key !== '') {
			if (payloadAttr !== '') {
				payloadAttr += '&';
			}
			payloadAttr += `${param.key}=${param.value}`;
		}
	});

	return payloadAttr;
};

export function validateNames(t: any, attributeName: any) {
	const restrictedAttributePatterns = ['attr_', 'context[.]', '{', '}', '^[+]', '^[-]'];
	const regex = new RegExp(restrictedAttributePatterns.join('|'));
	let isValid = true;
	if (attributeName && regex.test(attributeName.toLowerCase())) {
		isValid = false;

		return t('errors:invalidEntityNameText');
	}

	return isValid;
}

export const replaceVariablesInMsgArray = function (
	msg: any[],
	generatedvariables: any,
	isQuill: boolean,
	isEdit = false
) {
	let newArray: Array<IObjProps>;

	newArray = isQuill
		? msg.map((el: any) => ({
				text: replaceVariablesInMsg(processQuillContent(el.text, isEdit), generatedvariables),
			}))
		: msg.map((el: any) => replaceVariablesInMsg(el, generatedvariables));

	return newArray;
};

export const isExpressionBalanced = function (expr: any) {
	let count = 0;
	for (const index in expr) {
		if (expr[index] === '(') {
			count++;
		} else if (expr[index] === ')') {
			count--;
		}
		if (count < 0) {
			return false;
		}
	}
	if (count > 0) {
		return false;
	}

	return true;
};

export const getUndefinedValues = function (expr: any, conditions: any) {
	const validSet = ['or', 'OR', 'Or', 'And', 'AND', 'and'];
	const labels = conditions.length;
	for (let i = 1; i <= labels; i++) {
		validSet.push(`A${i.toString()}`);
	}
	expr = expr.replaceAll('(', '');
	expr = expr.replaceAll(')', '');

	const undefinedValues = [];
	const values = expr.split(' ');
	for (const index in values) {
		values[index] = values[index].trim();
		if (validSet.indexOf(values[index]) !== -1 || values[index].indexOf('{{') === 0 || values[index] === '') {
			continue;
		} else {
			undefinedValues.push(values[index]);
		}
	}

	return undefinedValues;
};

export const checkIfValidExpression = function (expr: any) {
	const operators = ['or', 'OR', 'Or', 'And', 'AND', 'and'];
	expr = expr.replaceAll('(', '');
	expr = expr.replaceAll(')', '');

	var values = expr.split(' ');
	var values = values.filter(function (value: any) {
		return value !== '';
	});
	if (
		values.length === 0 ||
		operators.indexOf(values[0].trim()) !== -1 ||
		operators.indexOf(values[values.length - 1].trim()) !== -1
	) {
		return false;
	}
	let index: any;
	for (index in values) {
		values[index] = values[index].trim();
		if (index % 2 === 0 && operators.indexOf(values[index]) !== -1) {
			return false;
		}
		if (index % 2 !== 0 && operators.indexOf(values[index]) === -1) {
			return false;
		}
	}

	return true;
};

export const getErrorMessageForFormFields = (name: string, errors: any) => {
	const dotIndex = name.indexOf('.');
	if (dotIndex > -1) {
		const resName = name.substring(dotIndex + 1, name.length); // test
		const arrNameRegex = new RegExp(/.*(?=\[)/, 'gm');
		const arrayName = arrNameRegex.exec(name)?.toString() || ''; // random_msgs
		const indexRegex = new RegExp(/(?:\[(.*)\])/, 'gm');
		const regexMatch = indexRegex.exec(name) || [];
		const index = parseInt(regexMatch[1]); // 0
		const err = arrayName && errors.hasOwnProperty(arrayName) ? errors[arrayName][index] : {};
		const errorMsg = err && err.hasOwnProperty(resName) ? err[resName] : '';

		return errorMsg;
	}
};

export const processChatHistoryAttrs = function (stringToChange: any, arrayOfKeysToCheck: any) {
	const textRegex = /{?{{chat.text}}?}/g;
	const urlRegex = /{?{{chat.URL}}?}/g;
	const fileRegex = /{?{{chat.file}}?}/g;
	stringToChange = stringToChange.toString().replace(urlRegex, '[[chat.URL]]');
	stringToChange = stringToChange.toString().replace(textRegex, '[[chat.text]]');
	stringToChange = stringToChange.toString().replace(fileRegex, '[[chat.file]]');

	const stringsMatched: Array<any> = [];

	arrayOfKeysToCheck.forEach(function (key: any) {
		if (stringToChange.indexOf(key) > -1) {
			stringsMatched.push(key.split('.')[1]);
		}
	});

	return {
		updatedString: stringToChange,
		stringsMatched,
	};
};

export const checkIfNodeNameExists = (name: string, nodeList: any) => {
	const nodeInList = nodeList.find((node: any) => node.node_name === name);
	if (nodeInList) {
		return true;
	}

	return false;
};

export const checkAndGenerateNodeName = (name: string, nodeList: any) => {
	if (!checkIfNodeNameExists(name, nodeList)) {
		return name;
	}
	let index = 1;
	while (checkIfNodeNameExists(`${name}_${index}`, nodeList)) {
		index++;
	}

	return `${name}_${index}`;
};

export const removeHtmlTags = (str: string) => {
	if (str === null || str === '') {
		return '';
	}
	str = str.toString();

	return str.replace(/(<([^>]+)>)/gi, '');
};

export const processNodeMessage = (str: string, pathVariables: any) => {
	if (str) {
		const htmlTagsRemoved = removeHtmlTags(str);
		const nodePreviewText = replaceKeyWithVariableName(htmlTagsRemoved, pathVariables);
		const updatedNodePreviewText = nodePreviewText.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

		return getEllipsisedText(updatedNodePreviewText, 30);
	}

	return '';
};

export const convertToNormalCase = (text: string) => {
	if (text) {
		return text
			.toLowerCase()
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	return '';
};

export const findNodeNameFromFlowKey = (flowKey: string, flowList: any) => {
	const nodeInList = flowList.find((node: any) => node.path_key === flowKey);
	if (nodeInList) {
		return nodeInList.display_name;
	}

	return '';
};

export const getNodeKey = (nextNodeKey: any, nodeList: any) => {
	// if next node is a rud node then the nodekey we get is `<node_key>_1` which doesn't match
	// any keys from the nodeList so in that case returning the nextNodeKey as recieved
	const nextNode = nextNodeKey && nodeList.find((n: any) => n.node_key === nextNodeKey);
	const nextNodeType = nextNode && nextNode.node_type;
	nextNodeKey = nextNode
		? nextNodeType
			? (nextNodeType === 'RCV_INP' || nextNodeType === 'RCV_INP_ENTITY') &&
				nextNode.node_key.indexOf('_1') === -1
				? `${nextNode.node_key}_1`
				: nextNode.node_key
			: null
		: nextNodeKey;

	return nextNodeKey;
};

export const getRandomInt = (min: number, max: number) => {
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min) + min);
};

export const convertXYCoordinatesToPixels = (
	startX: number, // X coord of the component
	startY: number, // // Y coord of the component
	heightOfComp: number, // height of component after which the menu should open
	widthOfComp: number // width of component after which the menu should open
): any => {
	const xy = [];
	const x = startX + widthOfComp / 2 - 200;
	const y = heightOfComp + startY + 30;
	xy[0] = `${x}px`; // left
	xy[1] = `${y}px`; // top

	return xy;
};

export const validateImageUrl = (url: string) => {
	url = url?.trim();
	const REGEX = new RegExp(
		/^(http[s]?:\/\/)?([^:\/\s]+)(:([^\/]*))?(\/\w+\.)*([^#?\s]+)(\?([^#]*))?(\.[jJ][pP][gG]|\.[jJ][pP][eE][gG]|\.[pP][nN][gG]|\.[gG][iI][fF])$/gm
	);

	return REGEX.test(url);
};
export const validateAudioUrl = (url: string) => {
	url = url?.trim();
	const REGEX = new RegExp(
		/^(http[s]?:\/\/)?([^:\/\s]+)(:([^\/]*))?(\/\w+\.)*([^#?\s]+)(\?([^#]*))?(\.[mM][pP]3|\.[wW][aA][vV]|\.[oO][gG][gG])$/gm
	);

	return REGEX.test(url);
};
export const validateCaptionUrl = (url: string) => {
	url = url?.trim();
	const REGEX = new RegExp(MEDIA_CAPTION_URL_CHECK);

	return REGEX.test(url);
};

export const isVimeoVideo = (url: string) => {
	const vimeoRegex = new RegExp(
		/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
	);

	return vimeoRegex.test(url);
};

export const isFacebookVideo = (url: string) => {
	const facebookRegex = new RegExp(
		/(?:https?:\/\/)?(?:www.|web.|m.)?(facebook|fb).(com|watch)\/(?:video.php\?v=\d+|(\S+)|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/
	);

	return facebookRegex.test(url);
};

export const isYoutubeVideo = (url: string) => {
	const youtubeRegex = new RegExp(
		/^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/gm
	);

	return youtubeRegex.test(url);
};

export const validateVideoUrl = (url: string) => {
	url = url?.trim();

	const mp4Regex = new RegExp(
		/^(http[s]?:\/\/)?([^:\/\s]+)(:([^\/]*))?(\/\w+\.)*([^#?\s]+)(\?([^#]*))?(\.[mM][pP]4|\.[mM][kK][vV])$/gm
	);
	const result = isVimeoVideo(url) || isYoutubeVideo(url) || isFacebookVideo(url) || mp4Regex.test(url);

	return result;
};
export const validateDocumentUrl = (url: string) => {
	url = url?.trim();
	const REGEX = new RegExp(
		/^(http[s]?:\/\/)?([^:\/\s]+)(:([^\/]*))?(\/\w+\.)*([^#?\s]+)(\?([^#]*))?(\.[dD][oO][cC]|\.[dD][oO][cC][xX]|\.[xX][lL][sS]|\.[xX][lL][sS][xX]|\.[pP][pP][tT]|\.[pP][pP][tT][xX]|\.[pP][dD][fF]|\.[zZ][iI][pP]|\.[rR][aA][rR]|\.[mM][pP]4|\.[mM][pP]3|\.[mM][pP][eE][gG]|\.[tT][xX][tT]|\.[cC][sS][vV]|\.[mM][pP][gG][aA])$/gm
	);

	return REGEX.test(url);
};

export const memoisationEqualityCheck = (prev: any, next: any) => {
	let areEqual = true;

	Object.keys(next).forEach((key) => {
		if (typeof prev[key] === 'function') {
			return;
		}

		if (typeof prev[key] === 'object' && JSON.stringify(prev[key]) !== JSON.stringify(next[key])) {
			areEqual = false;
		} else if (typeof prev[key] !== 'object' && prev[key] !== next[key]) {
			areEqual = false;
		}
	});

	return areEqual;
};

export const getAllFlowsExcludingCurrentFlow = (flows: Array<IObjProps>, currentPathKey: string) =>
	flows
		.map((flow: IObjProps) => ({
			id: flow.path_key,
			label: flow.display_name,
		}))
		.filter((flow: IObjProps) => flow.id !== currentPathKey);
