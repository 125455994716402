export const DOC_LINKS_V2 = {
	home: 'https://help.engati.com/',
	botOverview: {
		path: 'https://docs.engati.com/documentation/building-your-bot/concepts-2/conversation-flow-paths/',
		faq: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/faq/',
		node: 'https://docs.engati.com/documentation/building-your-bot/nodes/',
	},
	analytics: {
		agentPerformance: 'https://help.engati.com/agent-performance',
	},
	train: {
		addFaq: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/faq/',
		faq: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/faq/',
		faqBlog: 'https://help.engati.com/faq/',
		entities: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/entities/',
		tabularInput: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/tabular-input/',
		cognitiveSearch: 'https://help.engati.com/docusense-search',
		configureSmartResponse:
			'https://docs.engati.com/documentation/harnessing-conversational-intelligence/configuring-conversational-intelligence/',
		configureCognitiveSearch: 'https://help.engati.com/docusense-search#cORbr',
		customPrompt: 'https://help.engati.com/guide-to-custom-prompting',
		platformTutorials: 'https://help.engati.com/platform-tutorials',
	},
	configure: {
		webDisplay: 'https://docs.engati.com/documentation/deploying-the-bot/website/website-theming/',
		liveChat: 'https://docs.engati.com/documentation/activating-live-chat/live-chat-settings/',
		teamManage: 'https://docs.engati.com/documentation/managing-your-account-2/manage-your-team/',
		language: 'https://docs.engati.com/documentation/building-your-bot/enabling-multiple-languages/',
		advancedVoice: 'https://docs.engati.com/documentation/building-your-bot/enabling-voice-bots/',
		advancedDataFormatting: 'https://docs.engati.com/documentation/building-your-bot/enabling-data-formatting/',
		deployment: {
			general: 'https://docs.engati.com/documentation/deploying-the-bot/',
			fb: 'https://docs.engati.com/documentation/deploying-the-bot/facebook/',
			whatsapp: 'https://docs.engati.com/documentation/deploying-the-bot/whatsapp/',
			web: 'https://docs.engati.com/documentation/deploying-the-bot/website/',
			telegram: 'https://docs.engati.com/documentation/deploying-the-bot/telegram/',
			slack: 'https://docs.engati.com/documentation/deploying-the-bot/slack/',
			teams: 'https://docs.engati.com/documentation/deploying-the-bot/microsoft-teams/',
			instagram: {
				link1: 'https://docs.engati.com/documentation/deploying-the-bot/instagram/pre-requisites/',
				link2: 'https://docs.engati.com/documentation/deploying-the-bot/instagram/',
			},
			mobile: {
				link1: 'https://docs.engati.com/documentation/deploying-the-bot/mobile-sdks-ios-android/mobile-sdk-android/',
				link2: 'https://docs.engati.com/documentation/deploying-the-bot/mobile-sdks-ios-android/mobile-sdk-ios/',
				link3: 'https://docs.engati.com/documentation/deploying-the-bot/mobile-sdks-ios-android/mobile-sdk-flutter/',
			},
			email: 'https://docs.engati.com/documentation/deploying-the-bot/email/',
			waAccelerator: 'https://help.engati.com/whatsapp-onboarding',
		},
		integrations: {
			home: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/',
			ga: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-analytics/',
			googleTranslate:
				'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-translate/',
			gorgias: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/gorgias/',
			freshdesk: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/freshdesk/',
			zendesk: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/zendesk/',
		},
		shopify: {
			integration: 'https://docs.engati.com/documentation/integrating-with-shopify/',
			integrationOnboarding: 'https://help.engati.com/onboarding-and-deployment',
			setupEngagementTriggers:
				'https://docs.engati.com/documentation/integrating-with-shopify/engagement-triggers/',
			GATriggerAnalyticsTemplate:
				'https://analytics.google.com/analytics/web/template?uid=IfJqUe0YQIqzOOuNtHZPyA',
			productDiscovery: 'https://docs.engati.com/documentation/integrating-with-shopify/product-discovery/',
		},
		woocommerce: {
			integration: 'https://help.engati.com/engati-for-woocommerce-onboarding-and-deployment',
		},
	},
	users: {
		segments: 'https://docs.engati.com/documentation/tracking-analytics/users/segments/',
		customersegments: 'https://help.engati.com/customer-segments',
		customAudience: 'https://help.engati.com/',
		contactList: 'https://help.engati.com/import-contact-list',
	},
	integrations: {
		cloud: {
			home: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/',
			freshdesk: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/freshdesk/',
			zendesk: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/zendesk/',
			gSheets: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-sheets/',
			gCalendar:
				'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-calendar/',
			gAdmin: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-admin-console/',
			salesforce: 'https://help.engati.com/salesforce',
			shiprocket: 'https://help.engati.com/shiprocket',
			razorpay: 'https://help.engati.com/razorpay',
		},
		c2e: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/c2e-bridge/',
		engatiApi: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/engati-apis/',
	},
	broadcast: {
		basic: 'https://docs.engati.com/documentation/broadcasts/activating-broadcasts/',
		campaigns: 'https://docs.engati.com/documentation/broadcasts/campaigns/',
		intro: 'https://help.engati.com/activating-broadcasts',
		segments: 'https://docs.engati.com/documentation/platforms/segments/',
		whatsAppTemplates: 'https://help.engati.com/send-whatsapp-template-message',
		whatsAppTemplatesTextMedia: 'https://help.engati.com/message-templates',
		whatsAppTemplatesCarousel: 'https://help.engati.com/carousel-message-templates',
		whatsAppTemplatesLTO: 'https://help.engati.com/offer-message-templates',
		whatsAppTemplatesAuthentication: 'https://help.engati.com/',
		generateCurl: 'https://help.engati.com/generate-api-for-whatsapp-templates',
		createMessageTemplate: 'https://help.engati.com/message-templates',
		templateMessageGuidelinesMeta: 'https://developers.facebook.com/docs/whatsapp/message-templates/guidelines',
		whatsappMarketingTemplate:
			'https://engati.com/blog/frequency-capping?utm_source=platform&utm_medium=broadcast-history&utm_campaign=error-code',
		whatsappCloudErrorCodes: 'https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/',
	},
	builder: {
		sm: 'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/send-message-and-send-message-with-options/',
		smo: 'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/send-message-and-send-message-with-options/',
		sendCarousel:
			'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/send-carousel/',
		sendImage: 'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/5337-2/',
		sendVideo: 'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/5337-2/',
		sendAudio: 'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/5337-2/',
		sendDocument: 'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/5337-2/',
		randomizeMessages:
			'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/random-message-and-custom-card/',
		customcard:
			'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/random-message-and-custom-card/',
		feedback: 'https://docs.engati.com/documentation/building-your-bot/nodes/data-input-nodes/feedback-node/',
		rud: 'https://docs.engati.com/documentation/building-your-bot/nodes/data-input-nodes/request-user-data/',
		rudLLM: 'https://help.engati.com',
		identity: 'https://docs.engati.com/documentation/building-your-bot/nodes/data-input-nodes/5574-2/',
		slider: 'https://docs.engati.com/documentation/building-your-bot/nodes/data-input-nodes/slider-node/',
		formnode: 'https://docs.engati.com/documentation/building-your-bot/nodes/data-input-nodes/form-node/',
		fileUpload: 'https://docs.engati.com/documentation/building-your-bot/nodes/data-input-nodes/5549-2/',
		filterFaq: ' https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/filter-faq/',
		triggerpath: 'https://help.engati.com/trigger-a-path',
		transferToAgent: 'https://help.engati.com/transfer-to-agent',
		script: 'https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/5582-2/',
		setuserattribute:
			'https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/set-user-attribute/',
		sendEmail: 'https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/5601-2/',
		pause: 'https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/pause-node/',
		pythonScript: 'https://help.engati.com/advanced-script-node',
		decision: 'https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/decision-node/',
		webview: 'https://docs.engati.com/documentation/building-your-bot/nodes/processing-nodes/webview-node/',
		subscribeToCampaign: 'https://docs.engati.com/documentation/broadcasts/campaigns/',
		unsubscribeToCampaign: 'https://docs.engati.com/documentation/broadcasts/campaigns/',
		zendesk: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/zendesk/',
		salesforce: 'https://help.engati.com/salesforce',
		freshdesk: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/freshdesk/',
		googleSheets: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-sheets/',
		googleCalendar:
			'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-calendar/',
		gorgias: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/gorgias/',
		jsonAPI: 'https://docs.engati.com/documentation/building-your-bot/configuring-integrations/json-api/',
		templateMessage:
			'https://docs.engati.com/documentation/building-your-bot/nodes/display-information-node/template-message/',
		zapier: 'https://help.engati.com/zapier',
		whatsAppFlows: 'https://help.engati.com/whatsapp-flows',
	},
	whatsAppManager: {
		numbers: {
			commonLink: 'https://help.engati.com/',
			waOnboardingLink: 'https://help.engati.com/whatsapp-tsp-onboarding',
			fbmLink: 'https://www.facebook.com/business/help/113163272211510',
			fbmAccountSetupLink: 'https://www.facebook.com/business/help/1710077379203657',
		},
	},
	privacyLink: 'https://www.engati.com/privacypolicy',
	termOfUse: 'https://www.engati.com/termsofuse',
	jiraCustomerIssueLink: 'https://coviam.atlassian.net/servicedesk/customer/portal/1',
	releaseNotes: 'https://docs.engati.com/doc/release-notes/',
	shopifyReleaseNotes: 'https://docs.engati.com/documentation/integrating-with-shopify/release-notes/',
	configureGoogleAnalytics:
		' https://docs.engati.com/documentation/building-your-bot/configuring-integrations/google-analytics/',
	faq: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/faq/',
	entity: 'https://docs.engati.com/documentation/harnessing-conversational-intelligence/entities/',
	shopify: {
		abandonedCheckout: 'https://help.engati.com/checkout-abandonment',
		orderUpdates: 'https://help.engati.com/order-updates',
		CodToPrepaid: 'https://help.engati.com/cash-on-delivery-to-prepaid-conversion',
	},
};
