import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { HeadingLarge } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTNote from 'components/UI/ENGTNote/ENGTNote';
import NavBotInfo from 'components/UI/NavBotInfo/NavBotInfo';

import { IPrimaryNavItems, IPrimaryShopifyNavItems } from 'shared/consts/types';

import { isShopifyEnabledAcc, isWhatsappAcceleratorTrialEnabled } from 'store/App/User/selectors';

import CommonNavigation from './module/Common';
import ShopifyNavigation from './module/Shopify';

export interface ISecondaryNavigationProps {
	page: IPrimaryNavItems | IPrimaryShopifyNavItems;
	heading?: string;
	isHeaderShown?: boolean;
	isNavBotInfoShown?: boolean;
	note?: { heading?: string; description?: string };
}

export const SecondaryNavigationLinks = ({ page }: { page: string }) => {
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);

	return isShopifyEnabled ? <ShopifyNavigation page={page} /> : <CommonNavigation page={page} />;
};

const SecondaryNavigation = (props: ISecondaryNavigationProps) => {
	const { page, heading = '', isHeaderShown = false, isNavBotInfoShown = true, note } = props;

	const [css] = useStyletron();
	const { t } = useTranslation(['common']);

	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);

	const renderPageName = () => {
		const pageNameCss = {
			fontStyle: 'normal',
			fontWeight: 'bold' as const,
			textAlign: 'left' as const,
			paddingLeft: '0rem',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap' as const,
		};

		return <HeadingLarge className={css(pageNameCss)}>{heading}</HeadingLarge>;
	};

	return (
		<Block
			className={css({
				paddingTop: '1.5rem',
				paddingBottom: '1.5rem',
				paddingRight: '1.25rem',
				paddingLeft: '1.25rem',
				overflow: 'auto',
				height: '100%',
				boxSizing: 'border-box',
			})}
		>
			<Block>{isNavBotInfoShown && <NavBotInfo />}</Block>
			{isHeaderShown && renderPageName()}
			<Block marginTop='2.5rem'>
				<SecondaryNavigationLinks page={page} />
			</Block>
			{note && !isWhatsappAcceleratorTrial && (
				<ENGTNote
					heading={note?.heading || t('common:note')}
					description={note?.description ? t(`${note.description}`) : ''}
				/>
			)}
		</Block>
	);
};

export default SecondaryNavigation;
