import { ACCOUNT_TYPES } from 'shared/consts/consts';
import { ENTITLEMENTS } from 'shared/enum';

import { i18nHelper as t } from 'i18nHelper';

export const upgradeBusiness = (planName = 'Business') =>
	`${planName} Plan comes with capabilities to directly connect with Salesforce, Google etc.; Script Node for enhanced data processing within the conversation flows.; Additional Business Channels to deploy your bot on Whatsapp, Microsoft Teams, Skype for Business & Mobile SDKs and even significantly enhanced NLP capabilities with support for Context, Customized Synonyms & auto-Intent Disambiguation.`;
const upgradeEnterprise =
	'Enterprise plan provides the ability to activate human takeover with auto routing across multiple channels.; Deploy the bot on WhatsApp & MS Teams, enable voice interactions, file uploads.; 13 month data retention and higher limits on interactions. Learn More.';
const upgradeProfessional =
	'Professional Plan allows you to Brand your bot. It also comes with key capabilities like seamless integration using Advanced JSON plugin or Zapier.; Decision & Identity Nodes.; Human takeover to chat with bot users and APIs for User List, Chat History.';

const isMasterAdmin = localStorage.getItem('loggedInUserAccountType') === ACCOUNT_TYPES.MASTER_ADMIN;

export const getUpgradeHeadingText = (heading: string, description: string) =>
	`${heading} is available with the ${description} plan`;

/**
 * if your node creates attributes please add those cases in all places you find
 * on searching 'NODES_THAT_CREATE_ATTR' throughout the project.
 */
export const NODES: any = () => ({
	'Display Info': [
		{
			key: 'SEND_MSG',
			label: 'Send Message',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Message',
				node_data: {},
				next_node: null,
				node_type: 'SEND_MSG',
			},
		},
		{
			key: 'SEND_MSG_OPTNS',
			label: 'Send Message With Options',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Message (with options)',
				node_data: {},
				next_node: null,
				node_type: 'SEND_MSG_OPTNS',
			},
		},
		{
			key: 'SEND_CAROUSEL',
			label: 'Send Carousel',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Carousel',
				node_data: {},
				next_node: null,
				node_type: 'SEND_CAROUSEL',
			},
		},
		{
			key: 'SEND_IMAGE',
			label: 'Send Image',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Image',
				node_data: {},
				next_node: null,
				node_type: 'SEND_IMAGE',
			},
		},
		{
			key: 'SEND_DOCUMENT',
			label: 'Send Document',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Send Document', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Document',
				node_data: {},
				next_node: null,
				node_type: 'SEND_DOCUMENT',
			},
		},
		{
			key: 'SEND_AUDIO',
			label: 'Send Audio',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Audio',
				node_data: {},
				next_node: null,
				node_type: 'SEND_AUDIO',
			},
		},
		{
			key: 'SEND_VIDEO',
			label: 'Send Video',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Video',
				node_data: {},
				next_node: null,
				node_type: 'SEND_VIDEO',
			},
		},
		{
			key: 'TEMPLATE_MESSAGE',
			label: 'Template Message',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: ENTITLEMENTS.ENABLE_TEMPLATE_MESSAGE,
			selectedNodeData: {
				node_key: '',
				node_name: 'Template Message',
				node_data: {},
				next_node: null,
				node_type: 'TEMPLATE_MESSAGE',
			},
		},
		{
			key: 'RNDM_MSG',
			label: 'Randomize Messages',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Randomize Messages',
				node_data: {},
				next_node: null,
				node_type: 'RNDM_MSG',
			},
		},
		{
			key: 'CARD_NODE',
			label: 'Custom Card',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_card_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Custom Card',
				node_data: {},
				next_node: null,
				node_type: 'CARD_NODE',
			},
		},
	],
	'Data Input': [
		{
			key: 'FEEDBACK_NODE',
			label: 'Feedback',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: true,
			isDisabled: false,
			entitlementAllowed: 'enable_feedback_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Feedback',
				node_data: {},
				next_node: null,
				node_type: 'FEEDBACK_NODE',
			},
		},
		{
			key: 'FILE_UPLOAD',
			label: 'Upload File',
			upgradeMarker: 'ENT',
			upgradeHeader: getUpgradeHeadingText('Upload File', 'Enterprise'),
			upgradeDescription: upgradeEnterprise,
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_file_upload',
			selectedNodeData: {
				node_key: '',
				node_name: 'Upload File',
				node_data: {},
				next_node: null,
				node_type: 'FILE_UPLOAD',
			},
		},
		{
			key: 'FORM_NODE',
			label: 'Form',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Form Node', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_form_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Form',
				node_data: {},
				next_node: null,
				node_type: 'FORM_NODE',
			},
		},
		{
			key: 'WA_FLOW_NODE',
			label: 'WhatsApp Flows',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_wa_flows',
			selectedNodeData: {
				node_key: '',
				node_name: 'WhatsApp Flows',
				node_data: {},
				next_node: null,
				node_type: 'WA_FLOW_NODE',
			},
		},
		{
			key: 'IDENTITY_NODE',
			label: 'Identity',
			upgradeMarker: 'PRO',
			upgradeHeader: getUpgradeHeadingText('Identity Node', 'Professional'),
			upgradeDescription: upgradeProfessional,
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_identity_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Identity',
				node_data: {},
				next_node: null,
				node_type: 'IDENTITY_NODE',
			},
		},
		{
			key: 'RCV_INP',
			label: 'Request User Data',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Request User Data',
				node_data: {},
				next_node: null,
				node_type: 'RCV_INP',
			},
		},
		{
			key: 'SLIDER_NODE',
			label: 'Slider',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Slider Node', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_slider_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Slider',
				node_data: {},
				next_node: null,
				node_type: 'SLIDER_NODE',
			},
		},
		{
			key: 'RECURRING_NOTIFICATION_NODE',
			label: 'Send Recurring Notification',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: ENTITLEMENTS.ENABLE_RECURRING_NOTIFICATION_NODE,
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Recurring Notification',
				node_data: {},
				next_node: null,
				node_type: 'RECURRING_NOTIFICATION_NODE',
			},
		},
		{
			key: 'LLM_RUD_NODE',
			label: 'LLM Conversational Node',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_smart_assist',
			selectedNodeData: {
				node_key: '',
				node_name: 'Conversational Data Capture',
				node_data: {},
				next_node: null,
				node_type: 'LLM_RUD_NODE',
			},
		},
	],
	'Process and Flow': [
		{
			key: 'start_a_flow',
			label: 'Trigger a path',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Trigger a path',
				node_data: { type: 'start_a_flow' },
				next_node: null,
				node_type: 'ACTN_NODE',
			},
		},
		{
			key: 'CONVERSATION_STATUS_NODE',
			label: 'Conversation Status',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: true,
			isDisabled: false,
			entitlementAllowed: 'enable_conversation_status_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Conversation Status',
				node_data: { type: 'CONVERSATION_STATUS_NODE' },
				next_node: null,
				node_type: 'CONVERSATION_STATUS_NODE',
			},
		},
		{
			key: 'email',
			label: 'Send Email',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_send_email_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Send Email',
				node_data: { type: 'email' },
				next_node: null,
				node_type: 'ACTN_NODE',
				action_type: 'email',
			},
		},
		{
			key: 'EVAL_LOGIC',
			label: 'Decision',
			upgradeMarker: 'PRO',
			upgradeHeader: getUpgradeHeadingText('Decision Node', 'Professional'),
			upgradeDescription: upgradeProfessional,
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_eval_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Decision',
				node_data: {},
				next_node: null,
				node_type: 'EVAL_LOGIC',
			},
		},
		{
			key: 'FAQ_FILTER',
			label: 'Filter Faq',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_faq_filter_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Filter Faq',
				node_data: {},
				next_node: null,
				node_type: 'FAQ_FILTER',
			},
		},
		{
			key: 'WEB_VIEW_NODE',
			label: 'Webview',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Webview', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_webview_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Webview',
				node_data: {},
				next_node: null,
				node_type: 'WEB_VIEW_NODE',
			},
		},
		{
			key: 'LIVE_CHAT_CATEGORY',
			label: 'Transfer to Agent',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: true,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Transfer to Agent',
				node_data: {},
				next_node: null,
				node_type: 'LIVE_CHAT_CATEGORY',
			},
		},
		{
			key: 'PAUSE_NODE',
			label: 'Pause',
			upgradeMarker: 'PRO',
			upgradeHeader: getUpgradeHeadingText('Pause Node', 'Professional'),
			upgradeDescription: upgradeProfessional,
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_pause_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Pause',
				node_data: {},
				next_node: null,
				node_type: 'PAUSE_NODE',
			},
		},
		{
			key: 'SCRIPT_NODE',
			label: 'Script',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Script Node', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_scripting_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Script',
				node_data: {},
				next_node: null,
				node_type: 'SCRIPT_NODE',
			},
		},
		{
			key: 'SET_USER_ATTRIBUTES',
			label: 'Set User Attribute',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: '',
			selectedNodeData: {
				node_key: '',
				node_name: 'Set User Attribute',
				node_data: {},
				next_node: null,
				node_type: 'SET_USER_ATTRIBUTES',
			},
		},
		{
			key: 'SUBSCRIBE_CAMPAIGN',
			label: 'Subscribe to Campaign',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Subscribe to Campaign', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_campaign_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Subscribe to Campaign',
				node_data: {},
				next_node: null,
				node_type: 'SUBSCRIBE_CAMPAIGN',
			},
		},
		{
			key: 'UNSUBSCRIBE_CAMPAIGN',
			label: 'Unsubscribe from campaign',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Unsubscribe from campaign', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_campaign_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Unsubscribe from campaign',
				node_data: {},
				next_node: null,
				node_type: 'UNSUBSCRIBE_CAMPAIGN',
			},
		},
		...(isMasterAdmin
			? [
					{
						key: 'PYTHON_SCRIPT_NODE',
						label: 'Advanced Script',
						upgradeMarker: '',
						plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
						isLiveChatRequired: false,
						isDisabled: false,
						entitlementAllowed: 'enable_advanced_script_node',
						selectedNodeData: {
							node_key: '',
							node_name: 'Advanced Script',
							node_data: {},
							next_node: null,
							node_type: 'PYTHON_SCRIPT_NODE',
						},
					},
				]
			: []),
		{
			key: 'SELECT_LANGUAGE',
			label: 'Select Language',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'multi_language_enabled',
			selectedNodeData: {
				node_key: '',
				node_name: 'Select Language',
				node_data: {},
				next_node: null,
				node_type: 'SELECT_LANGUAGE',
			},
		},
	],
	Integrations: [
		{
			key: 'FRESHDESK',
			label: 'Freshdesk',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_freshdesk_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Freshdesk',
				node_data: { integration_key: 'FRESHDESK', auth_type: 'ApiKeyAuth' },
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
		{
			key: 'GOOGLE_CALENDAR',
			label: 'Google Calendar',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Google Calendar', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_google_calendar_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Google Calendar',
				node_data: { integration_key: 'GOOGLE_CALENDAR', auth_type: 'OAuth2' },
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
		{
			key: 'GOOGLE_SHEETS',
			label: 'Google Sheets',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Google Sheets', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_google_sheets_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Google Sheets',
				node_data: { integration_key: 'GOOGLE_SHEETS', auth_type: 'OAuth2' },
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
		{
			key: 'GORGIAS',
			label: t('components:node.integrationNodes.gorgias.heading'),
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Gorgias', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			entitlementAllowed: 'enable_gorgias_node',
			isDisabled: false,
			selectedNodeData: {
				node_key: '',
				node_name: 'Gorgias',
				node_data: { integration_key: 'GORGIAS', auth_type: 'OAuth2' },
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
		{
			key: 'HTTP_REQUEST',
			label: 'Json Api',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			entitlementAllowed: 'enable_adv_json_api',
			isDisabled: false,
			selectedNodeData: {
				node_key: '',
				node_name: 'JSON API',
				node_data: {
					error_msg: t('components:node.scriptNode.defaultErrorMessageValue'),
					headers: { 'Content-Type': 'application/json' },
				},
				next_node: null,
				node_type: 'HTTP_REQUEST',
			},
		},
		{
			key: 'SALESFORCE',
			label: 'Salesforce',
			upgradeMarker: 'BIZ',
			upgradeHeader: getUpgradeHeadingText('Salesforce', 'Business'),
			upgradeDescription: upgradeBusiness(),
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_salesforce_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Salesforce',
				node_data: { integration_key: 'SALESFORCE', auth_type: 'OAuth2' },
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
		{
			key: 'SHOPIFY_NODE',
			label: 'Shopify',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_shopify_shopper_assitance',
			selectedNodeData: {
				node_key: '',
				node_name: 'Shopify',
				node_data: {},
				next_node: null,
				node_type: 'SHOPIFY_NODE',
			},
		},
		{
			key: 'SMS',
			label: 'SMS',
			upgradeMarker: 'ENT',
			upgradeHeader: getUpgradeHeadingText('SMS', 'Enterprise'),
			upgradeDescription: upgradeEnterprise,
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_sms_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'SMS',
				node_data: {
					integration_key: 'SMS_TWILIO',
					auth_type: 'BasicAuth',
				},
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
		{
			key: 'WHATSAPP_COMMERCE',
			label: 'WhatsApp Commerce',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: ENTITLEMENTS.ENABLE_WHATSAPP_COMMECE,
			selectedNodeData: {
				node_key: '',
				node_name: 'WhatsApp Commerce',
				node_data: {},
				next_node: null,
				node_type: 'WHATSAPP_COMMERCE_NODE',
			},
		},
		{
			key: 'WA_PAYMENT_NODE',
			label: 'WhatsApp Payment',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_wa_payment',
			selectedNodeData: {
				node_key: '',
				node_name: 'WhatsApp Payment',
				node_data: {},
				next_node: null,
				node_type: 'WA_PAYMENT_NODE',
			},
		},
		{
			key: 'ZAPIER',
			subType: 'Zapier',
			label: 'Zapier',
			upgradeMarker: 'PRO',
			upgradeHeader: getUpgradeHeadingText('Zapier', 'Professional'),
			upgradeDescription: upgradeProfessional,
			plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Zapier',
				node_data: { sub_type: 'Zapier' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'PIPEDRIVE_ZAPIER',
			subType: 'Pipedrive',
			label: 'Pipedrive via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Pipedrive via Zapier',
				node_data: { sub_type: 'Pipedrive' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'FRESHDESK_ZAPIER',
			subType: 'Freshdesk',
			label: 'Freshdesk via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Freshdesk via Zapier',
				node_data: { sub_type: 'Freshdesk' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'ZENDESK_ZAPIER',
			subType: 'Zendesk',
			label: 'Zendesk via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Zendesk via Zapier',
				node_data: { sub_type: 'Zendesk' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'MAILCHIMP_ZAPIER',
			subType: 'Mailchimp',
			label: 'Mailchimp via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Mailchimp via Zapier',
				node_data: { sub_type: 'Mailchimp' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'TWILIO_ZAPIER',
			subType: 'Twilio',
			label: 'Twilio via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Twilio via Zapier',
				node_data: { sub_type: 'Twilio' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},

		{
			key: 'GMAIL_ZAPIER',
			subType: 'Gmail',
			label: 'Gmail via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Gmail via Zapier',
				node_data: { sub_type: 'Gmail' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'MYSQL_ZAPIER',
			subType: 'Mysql',
			label: 'Mysql via Zapier',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zapier_integration',
			selectedNodeData: {
				node_key: '',
				node_name: 'Mysql via Zapier',
				node_data: { sub_type: 'Mysql' },
				next_node: null,
				node_type: 'ZAPIER',
			},
		},
		{
			key: 'ZENDESK',
			label: 'Zendesk',
			upgradeMarker: '',
			plansAllowed: ['ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
			isLiveChatRequired: false,
			isDisabled: false,
			entitlementAllowed: 'enable_zendesk_node',
			selectedNodeData: {
				node_key: '',
				node_name: 'Zendesk',
				node_data: { integration_key: 'ZENDESK', auth_type: 'ApiKeyAuth' },
				next_node: null,
				node_type: 'INTEGRATION',
			},
		},
	],
});
