import React, { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import ContactSalesModal from 'components/ContactModal/ContactModal';
import RequestRechargeModal from 'components/RequestRechargeModal/RequestRecharge';
import TrialDaysLeft from 'components/shared/TrialDaysLeft/TrialDaysLeft';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import {
	CREDITS_EXHAUSTED,
	ENFORCED,
	ENGATI_ANDROID_PLAY_STORE_LINK,
	ENGATI_IOS_APP_STORE_LINK,
} from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { ACCOUNT_ENFORCED_USAGE, CHANNELS, EXECUTION_TAB } from 'shared/enum';
import { PlgGTMTrackingIds } from 'shared/enum/trackingEnums';
import { isValidResponseObject } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import alertIcon from 'shared/icons/AlertIcon.svg';
import googlePlayStoreIcon from 'shared/icons/googlePlayStoreIcon.svg';
import iosAppStoreIcon from 'shared/icons/iosAppStoreIcon.svg';
import { sendPLGButtonClickEventToGA4 } from 'shared/trackingHelpers';

import { API as GET_USAGE_API } from 'store/AccountSettings/api';
import { getBotAdAuthTokenAction } from 'store/App/Bot';
import { INSTA_API } from 'store/App/Configure/API/Deployment/Instagram/api';
import {
	API,
	facebookConfigured,
	instagramConfigured,
	isCTWAEntitlementEnabledSelector,
	isWhatsappAcceleratorTrialEnabled,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User';
import { RootState } from 'store/rootReducer';
import { brandingPlatformNameSelector, isEngatiBrandSelector } from 'store/Users/selectors';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

import ENGTButton from '../ENGTButton/ENGTButton';

import { BannerContext } from './ENGTBannerContext';

export enum bannerTypes {
	TRACK_BANNER = 'trackBanner',
	TRACK_CREDIT_BANNER = 'trackCreditBanner',
}

export enum EnforcementReasons {
	NO_CREDITS_LEFT = 'NO_CREDITS_LEFT',
	NO_WALLET_BALANCE_LEFT = 'NO_WALLET_BALANCE_LEFT',
}

const root: any = document.querySelector(':root');

function ENGTBanner({ children }: any) {
	const [css, theme]: any = useStyletron();

	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const { pathname } = useLocation();
	const dispatch: any = useDispatch();

	const bannerRef = useRef<HTMLDivElement>(null);
	const [walletBalance, setWalletBalance] = useState<number>(0);
	const bots_route = matchPath(`/bots`, pathname);
	const overview_route = matchPath(`/bot/${botRef}/overview`, pathname);
	const revenue_dashbaord = matchPath(`/bot/${botRef}/overview/revenue-dashboard`, pathname);
	const ctwaRoutes = ['/broadcast/execution/ctwa', '/broadcast/engagement/ctwa', '/broadcast/clickToWhatsappAds'];
	const isCtwaRoute = ctwaRoutes.some((route) => pathname.startsWith(route));
	const messages_route = matchPath('/messages/', pathname);
	const users_route = matchPath('/users', pathname);
	const wa_phone_route = matchPath('/wa-phone', pathname);
	const login_route = matchPath('/login', pathname);
	const wa_get_started_route = matchPath('/wa-get-started', pathname);
	const { t } = useTranslation(['pages', 'common']);

	const [isBannerModelOpen, setBannerModelOpen] = useState<boolean>(false);
	const [showBanner, setShowBanner] = useState<boolean>(true);
	const [showCreditBanner, setShowCreditBanner] = useState<boolean>(true);
	const [isCreditBanner, setIsCreditBanner] = useState<boolean | null>();
	const [isWalletBanner, setIsWalletBanner] = useState<boolean>(false);
	const [contactSales, setContactSales] = useState<boolean>(false);
	const [showUsersBanner, setShowUsersBanner] = useState<boolean>(false);
	const [supportEmail, setSupportEmail] = useState<string>('');
	const [enforcementStatus, setEnforcementStatus] = useState<string>('');
	const [enforcementReason, setEnforcementReason] = useState<string>('');
	const [isOpen, setOpen] = useState<boolean>(false);
	const [showIGBanner, setShowIGBanner] = useState<boolean>(false);
	const [showFBBanner, setShowFBBanner] = useState<boolean>(false);
	const [showFBAdBanner, setShowFbAdBanner] = useState<boolean>(false);

	const trackBanner = localStorage.getItem(bannerTypes.TRACK_BANNER);

	const { isSmallDesktopScreen } = useResponsiveSize();

	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const isPaymentNotificationBannerVisible = useSelector(
		(state: RootState) => state.Plan.details.data?.isPaymentNotificationBannerVisible
	);
	const stripeIdInCustomerDetails = useSelector((state: RootState) => state.User.stripeIdInCustomerDetails);

	const themeColor: string = useSelector((state: RootState) => state.User.botAdmin.data.theme_color);
	const email = useSelector((state: RootState) => state.User.botAdmin.data.email);
	const supportEmailOrLink = useSelector((state: RootState) => state.User.botAdmin.data.support_email);
	const brandName: string = useSelector(brandingPlatformNameSelector) || '';
	const isInstagramConfigured = useSelector(instagramConfigured);
	const isFacebookConfigured = useSelector(facebookConfigured);
	const isCreditBillingEnabled: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_credit_billing_enabled
	);
	const isWalletBillingEnabled: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_wallet_billing_enabled
	);
	const creditNotificationEmail = useSelector(
		(state: RootState) => state.User.botAdmin.data.credits_notification_email
	);
	const marketingCampaignsAnalyticsEnabled = useSelector(marketingCampaignsAnalyticsSelector);
	const isCTWAEntitlementEnabled = useSelector(isCTWAEntitlementEnabledSelector);
	const adAuthToken = useSelector((state: RootState) => state.Bot.data.adAuthToken);

	const banners = [
		{
			id: 1,
			show: showIGBanner,
			channel: CHANNELS.INSATAGRAM_CAPITALISED,
			deploymentPath: ThreeColumnRouteLinks.configureDepInstaLink,
		},
		{
			id: 2,
			show: showFBBanner,
			channel: CHANNELS.FACEBOOK_CAPITALISED,
			deploymentPath: ThreeColumnRouteLinks.configureDepFbLink,
		},
		{
			id: 3,
			show: showFBAdBanner,
			channel: CHANNELS.META_ADS,
			deploymentPath: marketingCampaignsAnalyticsEnabled
				? `${ThreeColumnRouteLinks.executionLink}/` + `${EXECUTION_TAB.CTWA}`
				: ThreeColumnRouteLinks.clickToWhatsappAdsLink,
		},
	];

	const channelName = banners.find((banner) => banner.show)?.channel;

	const navigate = useNavigate();
	const [showLoaderOnBillingPortalButton, setShowLoaderOnBillingPortalButton] = useState<boolean>(false);

	const bannerVisibility = () => {
		const creditBannerTrack = localStorage.getItem(bannerTypes.TRACK_CREDIT_BANNER);

		const isWAAcceleratorBannerVisible = () =>
			isWhatsappAcceleratorTrial && !(wa_phone_route || wa_get_started_route || login_route);

		return (
			isWAAcceleratorBannerVisible() ||
			isPaymentNotificationBannerVisible ||
			(isCreditBanner
				? showCreditBanner && creditBannerTrack === 'true' && bots_route
				: showBanner &&
					(isEngatiBrand || enforcementStatus === CREDITS_EXHAUSTED) &&
					((trackBanner === 'true' &&
						(overview_route || messages_route || revenue_dashbaord || (isCtwaRoute && showFBAdBanner))) ||
						(enforcementStatus === CREDITS_EXHAUSTED && bots_route) ||
						users_route))
		);
	};

	const showChannelBanner = () => showIGBanner || showFBBanner || showFBAdBanner;

	const onClickGooglePlayStoreIcon = () => {
		setBannerModelOpen(false);
		window.open(ENGATI_ANDROID_PLAY_STORE_LINK, '_blank');
	};

	const onClickIosAppStoreIcon = () => {
		setBannerModelOpen(false);
		window.open(ENGATI_IOS_APP_STORE_LINK, '_blank');
	};

	const contactSalesCall = () => {
		if (supportEmailOrLink?.type === 'link') {
			window.open(supportEmailOrLink.value, '_blank')?.focus();
		} else if (supportEmailOrLink?.type === 'email') {
			setSupportEmail(creditNotificationEmail);
			setContactSales(true);
		}
	};

	const onBannerClick = (isCreditBanner: boolean) => {
		if (isCreditBanner) {
			GET_USAGE_API.getCreditUsage().then(
				(response: any) => {
					if (isValidResponseObject(response)) {
						if (response.data.responseObject.redirectLink !== '') {
							window.open(response.data.responseObject.redirectLink, '_blank')?.focus();
						} else {
							contactSalesCall();
						}
					} else {
						contactSalesCall();
					}
				},
				(error: any) => {
					contactSalesCall();
				}
			);
		} else {
			setBannerModelOpen(true);
		}
	};
	const failedToaster = () =>
		toaster.negative(<ENGTToasterContainer title={t('common:failed')} description={t('common:wentWrong')} />, {});

	const getWalletBalance = () => {
		GET_USAGE_API.getWalletUsage()
			.then((walletBalanceData: IObjProps) => {
				const { walletBalance, error } = walletBalanceData;
				if (walletBalance) {
					setWalletBalance(walletBalance);
				} else if (error) {
					failedToaster();
				}
			})
			.catch(failedToaster);
	};

	useEffect(() => {
		if (isWalletBillingEnabled && !login_route) {
			getWalletBalance();
		}
	}, [isWalletBillingEnabled]);

	const onBannerClose = () => {
		if (isCreditBanner) {
			setShowCreditBanner(false);
			localStorage.setItem(bannerTypes.TRACK_CREDIT_BANNER, 'false');
		} else {
			setShowBanner(false);
			localStorage.setItem(bannerTypes.TRACK_BANNER, 'false');
		}
	};

	const closeIcon = (
		<div
			className={css({
				paddingTop: '0.125rem',
				fontWeight: 600,
				color: showChannelBanner() ? theme.colors.channelBannerText : null,
			})}
			onClick={onBannerClose}
		>
			&#x2715;
		</div>
	);

	useEffect(() => {
		if (
			((isCreditBillingEnabled || isWalletBillingEnabled) && bots_route && isCreditBanner === undefined) ||
			overview_route ||
			!enforcementStatus
		) {
			API.getCustomerEnforcementDetails().then((response: any) => {
				if (response.data && response.data.success) {
					const { enforcementStatus, enforcementReason } = response.data.customer_enforcement;
					const isCreditBannerEnabled =
						enforcementStatus === ENFORCED && enforcementReason === EnforcementReasons.NO_CREDITS_LEFT;
					const isWalletBannerEnabled =
						enforcementStatus === CREDITS_EXHAUSTED &&
						enforcementReason === EnforcementReasons.NO_WALLET_BALANCE_LEFT;
					setEnforcementStatus(enforcementStatus);
					setIsCreditBanner(isCreditBannerEnabled);
					setIsWalletBanner(isWalletBannerEnabled);
					setEnforcementReason(enforcementReason);
					localStorage.setItem(bannerTypes.TRACK_CREDIT_BANNER, isCreditBannerEnabled.toString());
				}
			});
			if (overview_route || revenue_dashbaord) {
				dispatch(getBotAdAuthTokenAction());
			}
		}
	}, [bots_route]);

	useEffect(() => {
		if (
			(overview_route || revenue_dashbaord || isCtwaRoute) &&
			isEngatiBrand &&
			(isInstagramConfigured || isFacebookConfigured || adAuthToken)
		) {
			const platform = [];
			isInstagramConfigured && platform.push('INSTAGRAM');
			isFacebookConfigured && platform.push('FACEBOOK');
			INSTA_API.getTokenStatus(platform.join(',')).then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					const { responseObject } = response.data;
					setShowIGBanner(isInstagramConfigured && !responseObject.instagramTokenValid);
					setShowFBBanner(isFacebookConfigured && !responseObject.facebookTokenValid);
					setShowFbAdBanner(!!(adAuthToken && isCTWAEntitlementEnabled && !responseObject.adTokenValid));
				}
			});
		} else {
			setShowIGBanner(false);
			setShowFBBanner(false);
			setShowFbAdBanner(false);
		}
	}, [overview_route, revenue_dashbaord, isCtwaRoute]);

	useEffect(() => {
		setShowUsersBanner(Boolean(users_route));
	}, [users_route]);

	useEffect(() => {
		if (isCreditBillingEnabled === false && isCreditBanner === false) {
			localStorage.setItem(bannerTypes.TRACK_CREDIT_BANNER, 'false');
		}
	}, [isCreditBillingEnabled, isCreditBanner]);

	useEffect(() => {
		if (bannerRef?.current) {
			root.style.setProperty('--engt-app-banner-height', `${bannerRef?.current?.clientHeight ?? 0}px`);
		}
	});

	const bannerStaticHeight = isWhatsappAcceleratorTrial ? 50 : 34;

	const rightSectionWrapper = (data: React.ReactElement) => (
		<div
			className={css({
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				letterSpacing: '-0.005em',
				fontFamily: 'Hind',
				textAlign: 'right',
				position: 'absolute',
				right: '2.5rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			})}
		>
			{data}
		</div>
	);

	const renderRightSection = () => {
		if (isWhatsappAcceleratorTrial) {
			return isSmallDesktopScreen ? <></> : rightSectionWrapper(<TrialDaysLeft />);
		}

		return !showUsersBanner && enforcementStatus !== CREDITS_EXHAUSTED && rightSectionWrapper(closeIcon);
	};

	const renderActionModel = () => (
		<ActionModal
			description={t('pages:banner.modal.description')}
			heading={t('pages:banner.modal.heading')}
			height='11rem'
			isFooterReq={false}
			isOpen={isBannerModelOpen}
			onClose={() => setBannerModelOpen(false)}
			onConfirm={() => {}}
			autoFocus={false}
		>
			<div
				className={css({
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				})}
			>
				<ENGTImage
					lazy
					src={googlePlayStoreIcon}
					alt='Google Play Store'
					className={css({
						cursor: 'pointer',
						margin: '0.5rem',
						width: '10rem',
					})}
					onClick={onClickGooglePlayStoreIcon}
				/>
				<ENGTImage
					lazy
					src={iosAppStoreIcon}
					alt='Ios App Store'
					className={css({
						cursor: 'pointer',
						margin: '0.5rem',
						width: '10rem',
					})}
					onClick={onClickIosAppStoreIcon}
				/>
			</div>
		</ActionModal>
	);

	const pushToChannelDeployment = () => {
		const activeBanner = banners.find((banner) => banner.show);
		if (activeBanner) {
			navigate(activeBanner.deploymentPath);
		} else {
			console.error('No active channel found!');
		}
	};

	const generateCustomerPortalLink = () => {
		setShowLoaderOnBillingPortalButton(true);
		GET_USAGE_API.getStripeCustomerPortal()
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					window.open(response.data.responseObject, '_blank');
				} else {
					failedToaster();
				}
			})
			.catch(() => {
				failedToaster();
			})
			.finally(() => {
				setShowLoaderOnBillingPortalButton(false);
			});
	};

	return (
		<BannerContext.Provider value={bannerVisibility() ? bannerStaticHeight : 0}>
			<div id='portal-banner' ref={bannerRef}>
				{bannerVisibility() && (
					<div
						className={css({
							cursor: 'pointer',
							width: 'auto',
							height: showChannelBanner() || isWhatsappAcceleratorTrial ? '3.125rem' : '2.125rem',
							color: theme.colors.primaryTextColor,
							backgroundColor: showChannelBanner()
								? '#EED8DB'
								: isCreditBanner
									? themeColor
									: theme.colors.accent,
							display: 'flex',
							justifyContent: showChannelBanner() ? 'left' : 'center',
							alignItems: 'center',
							position: 'relative',
							borderLeft: showChannelBanner() ? '0.249rem solid #CF4D4B' : null,
							borderRight: showChannelBanner() ? '0.0625rem solid #E9B4B5' : null,
							borderTop: showChannelBanner() ? '0.0625rem solid #E9B4B5' : null,
							borderBottom: showChannelBanner() ? '0.0625rem solid #E9B4B5' : null,
						})}
					>
						<div
							className={css({
								paddingTop: '0.125rem',
								fontSize: '0.875rem',
								lineHeight: '1.25rem',
								letterSpacing: '-0.005em',
								fontFamily: 'Hind',
								fontWeight: 600,
								display: 'flex',
								paddingLeft: '0.5rem',
								paddingRight: '0.5rem',
								alignItems: 'center',
							})}
						>
							{showChannelBanner() ? (
								<div className={css({ display: 'flex', alignItems: 'center' })}>
									<ENGTImage
										lazy
										src={alertIcon}
										alt='alert-icon'
										className={css({
											marginLeft: '1.25rem',
											marginRight: '0.75rem',
											marginBottom: '0.25rem',
										})}
									/>
									<div>
										<span className={css({ fontSize: '1rem', color: theme.failure })}>
											{t('pages:banner.channelsBanner.error')}
										</span>{' '}
										<span
											className={css({
												color: theme.colors.channelBannerText,
												fontWeight: 500,
											})}
										>
											{t('pages:banner.channelsBanner.bannerText1', { channelName })}
											<span
												className={css({
													textDecoration: 'underline',
													fontWeight: 'bold',
													color: theme.failure,
												})}
												onClick={() => pushToChannelDeployment()}
											>
												{t('pages:banner.clickHere')}
											</span>{' '}
											{t('pages:banner.channelsBanner.bannerText2')}
										</span>
									</div>
								</div>
							) : isPaymentNotificationBannerVisible ? (
								<>
									{t('pages:banner.renewSubscription')}
									<ENGTButton
										size={SIZE.compact}
										kind={KIND.tertiary}
										label={t('pages:accountSettings.subscriptionDetailsPage.viewInvoices')}
										className={css({
											marginLeft: '0.75rem !important',
											color: theme.colors.accent,
										})}
										isLoading={showLoaderOnBillingPortalButton}
										onClick={generateCustomerPortalLink}
									/>
								</>
							) : isWhatsappAcceleratorTrial && isEngatiBrand ? (
								<>
									{t('pages:upgradeRequestForWAAccelerator.bannerText')}
									<ENGTButton
										size={SIZE.compact}
										kind={KIND.tertiary}
										label={t('pages:upgradeRequestForWAAccelerator.viewPricing')}
										className={css({
											marginLeft: '0.75rem !important',
											color: theme.colors.accent,
											background: `${theme.colors.primaryBtnTextColor} !important`,
											whiteSpace: 'nowrap',
										})}
										onClick={() => {
											sendPLGButtonClickEventToGA4({
												gtmTrackingId: PlgGTMTrackingIds.UPGRADE_NOW_BANNER_BUTTON_CLICKED,
											});
											navigate(NoColumnRouteLinks.billingPlans, { replace: true });
										}}
									/>
								</>
							) : showUsersBanner ? (
								t('pages:banner.usersBanner')
							) : enforcementStatus === CREDITS_EXHAUSTED ? (
								<>
									<RequestRechargeModal
										isWalletBanner={isWalletBanner}
										isOpen={isOpen}
										setRequestRechargeOpen={setOpen}
									/>
									{isWalletBanner ? (
										<>
											<span>
												{t('pages:banner.requestWalletRecharge.limitedAccessLowBalance', {
													balance: walletBalance,
												})}
											</span>
											<ENGTButton
												label={t('pages:banner.requestWalletRecharge.rechargeNow')}
												kind={KIND.minimal}
												size={SIZE.compact}
												onClick={() =>
													navigate(`${NoColumnRouteLinks.usageDetails}?openSelfRecharge=true`)
												}
												className={css({
													height: '1.75rem',
													marginBottom: '0.125rem',
													marginLeft: '0.625rem',
													color: theme.colors.accent,
													fontWeight: 600,
												})}
											/>
										</>
									) : (
										<>
											{t('pages:banner.requestRechargeBannerLowCreditsLessFeatures')}
											<span
												className={css({
													textDecoration: 'underline',
													marginRight: '0.313rem',
													marginLeft: '0.313rem',
												})}
												onClick={() => setOpen(true)}
											>
												{t('pages:banner.clickHere')}
											</span>
											{t('pages:banner.requestRechargeBannerToRequestRecharge')}
										</>
									)}
								</>
							) : (
								<div>
									{isCreditBanner ? t('pages:banner.creditBanner1') : t('pages:banner.bannerText1')}
									<span
										className={css({
											textDecoration: 'underline',
										})}
										onClick={() => onBannerClick(isCreditBanner || false)}
									>
										{t('pages:banner.clickHere')}
									</span>
									{isCreditBanner && t('pages:banner.creditBanner2')}
								</div>
							)}
						</div>
						{renderRightSection()}
						{renderActionModel()}
						<ContactSalesModal
							isModalOpen={contactSales}
							toggleModal={setContactSales}
							options={{
								preSubject: `${t('components:contactSales.creditsNotAvailable')} ${email}`,
								contactMode: 'contact',
							}}
							heading={`${t('components:contactSales.contact')} ${brandName} ${t(
								'components:contactSales.team'
							)}`}
							usage={ACCOUNT_ENFORCED_USAGE.ACCOUNT_ENFORCED}
							email={supportEmail}
						/>
					</div>
				)}
			</div>
			{children}
		</BannerContext.Provider>
	);
}

export { ENGTBanner };
