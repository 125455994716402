// Overview
export const CONFIGURE_OVERVIEW_DEPLOY_BOT = 'configure-overview-deploy-bot';
export const CONFIGURE_OVERVIEW_MANAGE_LIVE_CHAT = 'configure-overview-manage-live-chat';
export const CONFIGURE_OVERVIEW_MANAGE_DISPLAY = 'configure-overview-manage-display';

// Bot Details
export const CONFIGURE_BOT_DETAILS_BOT_NAME = 'configure-bot-details-bot-name';
export const CONFIGURE_BOT_DETAILS_WEBSITE_URL = 'configure-bot-details-website-url';
export const CONFIGURE_BOT_DETAILS_KEY_IDENTIFIER = 'configure-bot-details-key-identifier';
export const CONFIGURE_BOT_DETAILS_DESCRIPTION = 'configure-bot-details-description';
export const CONFIGURE_BOT_DETAILS_PRIMARY_LANGUAGE = 'configure-bot-details-primary-language';
export const CONFIGURE_BOT_DETAILS_PRIMARY_TIMEZONE = 'configure-bot-details-primary-timezone';
export const CONFIGURE_BOT_DETAILS_OVERRIDE_TIMEZONE = 'configure-bot-details-override-timezone';
export const CONFIGURE_BOT_DETAILS_AUTO_RESOLVE = 'configure-bot-details-auto-resolve';
export const CONFIGURE_BOT_DETAILS_SAVE = 'configure-bot-details-save';

// Web Display
export const CONFIGURE_WEB_DISPLAY_ADD_NEW_CARD = 'configure-web-display-add-new-card';
export const CONFIGURE_WEB_DISPLAY_UPGRADE = 'configure-web-display-upgrade';
export const CONFIGURE_WEB_DISPLAY_UPGRADE_PLAN = 'configure-web-display-upgrade-plan';
export const CONFIGURE_WEB_DISPLAY_SHOW_LAUNCH_SCREEN = 'configure-web-display-show-launch-screen';
export const CONFIGURE_WEB_DISPLAY_LAUNCH_SCREEN_TITLE = 'configure-web-display-launch-screen-title';
export const CONFIGURE_WEB_DISPLAY_SHOW_AVAILABILITY = 'configure-web-display-show-availability';
export const CONFIGURE_WEB_DISPLAY_SHOW_RESPONSIVENESS = 'configure-web-display-show-responsiveness';
export const CONFIGURE_WEB_DISPLAY_AGENT_RESPONSIVESNESS_TEXT = 'configure-web-display-agent-responsivesness-text';
export const CONFIGURE_WEB_DISPLAY_QUERY_CARD_TITLE = 'configure-web-display-query-card-title';
export const CONFIGURE_WEB_DISPLAY_QUERY_PLACEHOLDER = 'configure-web-display-query-placeholder';
export const CONFIGURE_WEB_DISPLAY_DEPLOYMENT_SETTINGS = 'configure-web-display-deployment-settings';
export const CONFIGURE_WEB_DISPLAY_LAUNCH_ICON = 'configure-web-display-launch-icon';
export const CONFIGURE_WEB_DISPLAY_CHAT_CLOSE_PLACEMENT = 'configure-web-display-chat-close-placement';
export const CONFIGURE_WEB_DISPLAY_CHAT_MENU = 'configure-web-display-chat-menu';
export const CONFIGURE_WEB_DISPLAY_REFRESH_CHAT_BUTTON = 'configure-web-display-refresh-chat-button';
export const CONFIGURE_WEB_DISPLAY_CHAT_HEADER_FONT = 'configure-web-display-chat-header-font';
export const CONFIGURE_WEB_DISPLAY_CHAT_HEADER_FONT_COLOR = 'configure-web-display-chat-header-font-color';
export const CONFIGURE_WEB_DISPLAY_CHAT_HEADER_ALIGNMENT = 'configure-web-display-chat-header-alignment';
export const CONFIGURE_WEB_DISPLAY_CHAT_HEADER_BACKGROUND_COLOR = 'configure-web-display-chat-header-background-color';
export const CONFIGURE_WEB_DISPLAY_HEADER_TEXT = 'configure-web-display-header-text';
export const CONFIGURE_WEB_DISPLAY_HEADER_ICON = 'configure-web-display-header-icon';
export const CONFIGURE_WEB_DISPLAY_CHAT_BACKGROUND_TYPE = 'configure-web-display-chat-background-type';
export const CONFIGURE_WEB_DISPLAY_TOP_STOP_COLOR = 'configure-web-display-top-stop-color';
export const CONFIGURE_WEB_DISPLAY_MID_STOP_COLOR = 'configure-web-display-mid-stop-color';
export const CONFIGURE_WEB_DISPLAY_BOTTOM_STOP_COLOR = 'configure-web-display-bottom-stop-color';
export const CONFIGURE_WEB_DISPLAY_CHAT_TRANSPARENCY = 'configure-web-display-chat-transparency';
export const CONFIGURE_WEB_DISPLAY_CHAT_WALLPAPER = 'configure-web-display-chat-wallpaper';
export const CONFIGURE_WEB_DISPLAY_CHAT_VIDEO = 'configure-web-display-chat-video';
export const CONFIGURE_WEB_DISPLAY_CHAT_AVATAR = 'configure-web-display-chat-avatar';
export const CONFIGURE_WEB_DISPLAY_RESPONSE_TEXT_BUBBLE_COLOR = 'configure-web-display-response-text-bubble-color';
export const CONFIGURE_WEB_DISPLAY_RESPONSE_TEXT_COLOR = 'configure-web-display-response-text-color';
export const CONFIGURE_WEB_DISPLAY_RESPONSE_BUTTON_COLOR = 'configure-web-display-response-button-color';
export const CONFIGURE_WEB_DISPLAY_USER_TEXT_BUBBLE_COLOR = 'configure-web-diaply-user-text-bubble-color';
export const CONFIGURE_WEB_DISPLAY_USER_TEXT_COLOR = 'configure-web-display-user-text-color';
export const CONFIGURE_WEB_DISPLAY_REPLY_AREA_TEXT = 'configure-web-display-reply-area-text';
export const CONFIGURE_WEB_DISPLAY_REPLY_AREA_BG_COLOR = 'configure-web-display-reply-area-bg-color';
export const CONFIGURE_WEB_DISPLAY_SAVE = 'configure-web-display-save';
export const CONFIGURE_WEB_DISPLAY_TRIGGER = 'configure-web-display-trigger';
export const CONFIGURE_WEB_DISPLAY_TRIGGER_DELAY = 'configure-web-display-trigger-delay';
export const CONFIGURE_WEB_DISPLAY_CHAT_POPUP_TYPE = 'configure-web-display-chat-popup-type';
export const CONFIGURE_WEB_DISPLAY_CHAT_POPUP_MESSAGE = 'configure-web-display-chat-popup-message';
export const CONFIGURE_WEB_DISPLAY_CHAT_AUTO_POPUP = 'configure-web-display-chat-auto-popup';
export const CONFIGURE_WEB_DISPLAY_AGENT_NOTIFICATION = 'configure-web-display-agent-notification';
export const CONFIGURE_WEB_DISPLAY_RUD_RESPONSE_BOX_ENABLED = 'configure-web-display-rud-response-box-enabled';
export const CONFIGURE_WEB_DISPLAY_EDIT_PREV_USER_MESSAGE = 'configure-web-display-edit-prev-user-message';
export const CONFIGURE_WEB_DISPLAY_DISABLE_SMO_OPTIONS = 'configure-web-display-disable-smo-options';
export const CONFIGURE_WEB_DISPLAY_BLOCK_SEND_MESSAGE_WITH_OPTIONS =
	'configure-web-display-block-send-message-with-options';
export const CONFIGURE_WEB_DISPLAY_DEFAULT_RESPONSE_TEXT = 'configure-web-display-default-response-text';
export const CONFIGURE_WEB_DISPLAY_ENABLE_VOICE_INPUT = 'configure-web-display-enable-voice-input';
export const CONFIGURE_WEB_DISPLAY_ENABLE_VOICE_CONV = 'configure-web-display-enable-voice-conv';
export const CONFIGURE_WEB_DISPLAY_ENABLE_CONTINUOUS_VOICE_MODE = 'configure-web-display-enable-continuous-voice-mode';
export const CONFIGURE_WEB_DISPLAY_VOICE_RESPONSE_GENDER = 'configure-web-display-voice-response-gender';
export const CONFIGURE_WEB_DISPLAY_VOICE_PHARSE_HINTS = 'configure-web-display-voice-phrase-hints';
export const CONFIGURE_WEB_DISPLAY_SUPPRESS_CTA_RESPONSE = 'configure-web-display-suppress-cta-response';
export const CONFIGURE_WEB_DISPLAY_CUSTOM_CSS = 'configure-web-display-custom-css';

// Live Chat
export const CONFIGURE_LIVE_CHAT_SAVE = 'configure-live_chat-save';
export const ADD_NEW_QUICK_REPLY = 'add-new-quick-reply';
export const ADD_CATEGORY = 'add-category';
export const CATEGORY_NAME = 'category-name';
export const CATEGORY_DESCRIPTION = 'category-description';
export const DELETE_QUICK_REPLY = 'delete-quick-reply';
export const EDIT_QUICK_REPLY = 'edit-quick-reply';
export const QUICK_REPLY_VALUE = 'quick-reply-value';
export const CONFIGURE_LIVE_CHAT_SETUP_WORK_WEEKS_CONFIGURE = 'configure-live-chat-setup-work-weeks-configure';
export const CONFIGURE_LIVE_CHAT_SETUP_WORK_WEEK_HOLIDAY = 'configure-live-chat-setup-work-week-holiday';
export const CONFIGURE_LIVE_CHAT_SETUP_WORK_WEEK_HOLIDAY_CLEARALL =
	'configure-live-chat-setup-work-week-holiday-clearall';
export const CONFIGURE_LIVE_CHAT_SETUP_WORK_WEEKS_ROUTE_TO_BOT_DETAILS =
	'configure-live-chat-work-weeks-route-to-bot-details';
export const CONFIGURE_LIVE_CHAT_SETUP_SETTINGS_REQUEST_FEEDBACK = 'configure-live-chat-setup-request-feedback';
export const CONFIGURE_LIVE_CHAT_SETUP_SETTINGS_EMAIL_TRANSCRIPT = 'configure-live-chat-setup-email-transcript';
export const CONFIGURE_LIVE_CHAT_SETUP_SETTINGS_SENDER_EMAIL = 'configure-live-chat-setup-sender-email';
export const CONFIGURE_LIVE_CHAT_SETUP_SEND_EMAIL_ON_RESOLUTION = 'configure-live-chat-setup-send-email-on-resolution';
export const CONFIGURE_LIVE_CHAT_SETUP_AGENT_BUSY_RESPONSE = 'configure-live-chat-setup-agent-busy-response';
export const CONFIGURE_LIVE_CHAT_SETUP_TRANSFER_TO_AGENT_RESPONSE =
	'configure-live-chat-setup-transfer-to-agent-response';
export const CONFIGURE_LIVE_CHAT_SETUP_AGENT_LEFT_CONVERSATION = 'configure-live-chat-setup-agent-left-conversation';
export const CONFIGURE_LIVE_CHAT_SETUP_AGENT_JOINED_CONVERSATION =
	'configure-live-chat-setup-agent-joined-conversation';
export const CONFIGURE_LIVE_CHAT_SETUP_EXIT_CONVERSATION = 'configure-live-chat-setup-exit-conversation';
export const CONFIGURE_LIVE_CHAT_SETUP_MAX_WAIT_TIME_WEBSITE = 'configure-live-chat-setup-max-wait-time-website';
export const CONFIGURE_LIVE_CHAT_SETUP_MAX_WAIT_TIME_APP = 'configure-live-chat-setup-max-wait-time-app';
export const CONFIGURE_LIVE_CHAT_SETUP_SLA_FOR_RESOLUTION = 'configure-live-chat-setup-sla-for-resolution';
export const CONFIGURE_LIVE_CHAT_SETUP_ALLOW_GROUP_CONVERSATION = 'configure-live-chat-setup-allow-group-conversation';
export const CONFIGURE_LIVE_CHAT_SETUP_ENABLE_VIDEO_CONVERSATION =
	'configure-live-chat-setup-enable-video-conversation';
export const CONFIGURE_LIVE_CHAT_SETUP_ENABLE_SCREEN_SHARE = 'configure-live-chat-setup-enable-screen-share';
export const CONFIGURE_LIVE_CHAT_SETUP_ENABLE_RECORDING = 'configure-live-chat-setup-enable-recording';
export const CONFIGURE_LIVE_CHAT_SETUP_JOIN_CONVERSATION_MESSAGE =
	'configure-live-chat-setup-join-conversation-message';
export const CONFIGURE_LIVE_CHAT_SETUP_DISCLAIMER_TEXT = 'configure-live-chat-setup-disclaimer-text';
export const CONFIGURE_LIVE_CHAT_SETUP_TEAM_ADD_CATEGORY = 'configure-live-chat-setup-team-add-category';
export const DELETE_RULE_SET = 'delete-rule-set';
export const ADD_RULE_SET = 'add-rule-set';
export const ADD_PRIORITY_RULE_SET = 'add-priority-rule-set';
export const CONFIGURE_LIVE_CHAT_ROUTING_ADD_RULE_SET = 'configure-live-chat-routing-add-rule-set';
export const CONFIGURE_LIVE_CHAT_SETUP_ENABLE_PRIORITISATION = 'configure-live-chat-setup-enable-prioritisation';
export const CONFIGURE_LIVE_CHAT_ROUTING_RULE_SET_NAME = 'configure-live-chat-routing-rule-set-name';
export const CONFIGURE_LIVE_CHAT_PRIORITY_RULE_SET_NAME = 'configure-live-chat-priority-rule-set-name';
export const CONFIGURE_LIVE_CHAT_ROUTING_RULE_EXPRESSION = 'configure-live-chat-routing-rule-expression';
export const CONFIGURE_LIVE_CHAT_PRIORITY_ROUTING_RULE_EXPRESSION =
	'configure-live-chat-priority-routing-rule-expression';
export const CONFIGURE_LIVE_CHAT_ROUTING_CATEGORY_TO_ASSIGN = 'configure-live-chat-routing-category-to-assign';
export const CONFIGURE_LIVE_CHAT_PRIORITY_TYPE = 'configure-live-chat-priority-type';
export const CONFIGURE_LIVE_CHAT_ADD_RULE = 'configure-live-chat-add-rule';
export const CONFIGURE_LIVE_CHAT_PRIORITY_ADD_RULE = 'configure-live-chat-priority-add-rule';
export const CONFIGURE_LIVE_CHAT_ADD_QUICK_REPLY = 'configure-live-chat-add-quick-reply';

// Team
export const CONFIGURE_TEAM_VIEW_RESOURCES = 'configure-team-view-resources';
export const CONFIGURE_TEAM_ADD_TEAM_MEMBER = 'configure-team-add-team-member';
export const CONFIGURE_TEAM_REFRESH = 'configure-team-refresh';
export const ADD_ADMIN = 'add-admin';
export const UPDATE_ADMIN = 'update-admin';
export const ADMIN_ROLE = 'admin-role';
export const ADMIN_NAME = 'admin-name';
export const ADMIN_EMAIL = 'admin-email';
export const ADMIN_CHANNELS = 'admin-channels';
export const ADMIN_CATEGORIES = 'admin-categories';
export const ASSIGN_CATEGORY = 'assign-category';
export const ASSIGN_CHANNEL = 'assign-channel';
export const DELETE_ADMIN = 'delete-admin';
export const RESET_PASSWORD = 'reset-password';
export const REST_PASSWORD_COPY = 'reset-password-copy';
export const VIEW_LOGS = 'view-logs';
export const ONLINE = 'online';
export const NEW_REQUEST = 'new-requests';
export const ACTIVE_CONVERSATION = 'active-conversations';

// Attributes
export const ATTRIBUTE_CHECK_REFERENCES = 'attribute-check-references';
export const CONFIGURE_ATTRIBUTE_DOWNLOAD = 'configure-attribute-download';
export const CONFIGURE_ATTRIBUTE_CREATE_CUSTOM = 'configure-attribute-create-custom';
export const CONFIGURE_ATTRIBUTE_CREATE_BOT = 'configure-attribute-create-bot';
export const CONFIGURE_ATTRIBUTE_CREATE_ECOMMERCE = 'configure-attribute-ecommerce-bot';
export const CREATE_ATTRIBUTE = 'create-attribute';
export const ATTRIBUTE_NAME = 'attribute-name';
export const ATTRIBUTE_TYPE = 'attribute-type';
export const ATTRIBUTE_VALUE = 'attribute-value';
export const ATTRIBUTE_HIERARCHY = 'attribute-hierarchy';
export const ATTRIBUTE_ENFORCE_VALIDATION = 'attribute-enforce-validation';
export const ATTRIBUTE_ENCRYPT = 'attribute-encrypt';
export const ATTRIBUTE_VALIDATION_TYPE = 'attribute-validation-type';
export const ATTRIBUTE_INPUT_REGEX = 'attribute-input-regex';
export const ATTRIBUTE_VALIDATION_MESSAGE = 'attribute-validation-message';
export const DELETE_ATTRIBUTE = 'delete-attribute';
export const EDIT_ATTRIBUTE = 'edit-attribute';
export const EDIT_ATTRIBUTE_NAME = 'edit-attribute-name';
export const EDIT_ATTRIBUTE_VALUE = 'edit-attribute-value';
export const EDIT_ATTRIBUTE_VALIDATION_TYPE = 'edit-attribute-validation-type';
export const EDIT_ATTRIBUTE_REGEX_VALIDATION = 'edit-attribute-regex-validation';
export const EDIT_ATTRIBUTE_VALIDATION_MESSAGE = 'edit-attribute-validation-message';

// Languages
export const CONFIGURE_LANGUAGE_ENABLE_AUTO_TRANSLATE = 'configure-language-enable-auto-translate';
export const BACK_TO_CONFIGURE_LANGUAGE = 'back-to-configure-language';
export const CONFIGURE_SELECT_LANGUAGE = 'configure-select-language';
export const ADD_LANGUAGE = 'add-language';
export const CONFIGURE_LANGUAGE_ENABLE = 'configure-language-enable';
export const CONFIGURE_LANGUAGE_UPGRADE = 'configure-language-upgrade';
export const CONFIGURE_ADD_LANGUAGE = 'configure-add-language';
export const CONFIGURE_LANGUAGE_SHOW_UPLOAD = 'configure-language-show-upload';
export const CONFIGURE_LANGUAGE_IMPORT_KEYS = 'configure-language-import-keys';
export const CONFIGURE_LANGUAGE_UPLOAD_KEYS = 'configure-language-upload-keys';
export const CONFIGURE_DEFAULT_LANGUAGE = 'configure-default-language';
export const CONFIGURE_DELETE_LANGUAGE = 'configure-delete-language';
export const CONFIGURE_UPLOAD_KEYS = 'configure-upload-keys';
export const CONFIGURE_LANGUAGE_UPLOAD_FILE = 'configure-langauge-upload-file';
export const CUSTOM_ATTRIBUTES_NAME_IN_USERS = 'custom-attributes-name-in-users';

// Advanced
export const CONFIGURE_ADVANCED_SAVE_CHANGES = 'configure-attribute-save';
export const CONFIGURE_ADVANCED_VOICE_UPLOAD_SPEECH_CONFIGURATION =
	'configure-advanced-voice-upload-speech-configuration';
export const CONFIGURE_ADVANCED_FORMATTING_DOCS = 'configure-advanced-formatting-docs';
export const CONFIGURE_ADVANCED_ADD_FORMATTING_GROUP = 'configure-advanced-add-formatting-group';
export const ADD_FORMATTING_GROUP = 'add-formatting-group';
export const CONFIGURE_ADVANCED_FORMATTING_IDENTIFIER = 'configure-advanced-formatting-identifier';
export const CONFIGURE_ADVANCED_FORMATTING_DIGIT_GROUPING = 'configure-advanced-formatting-digit-grouping';
export const CONFIGURE_ADVANCED_FORMATTING_GROUPING_SEPARATOR = 'configure-advanced-formatting-grouping-separator';
export const CONFIGURE_ADVANCED_FORMATTING_PREVIEW = 'configure-advanced-formatting-preview';
export const CONFIGURE_ADVANCED_FORMATTING_TIME_FORMAT = 'configure-advanced-formatting-time-format';
export const CONFIGURE_ADVANCED_FORMATTING_ADD_PRECEDING_ZERO = 'configure-advanced-formatting-add-preceding-zero';
export const CONFIGURE_ADVANCED_FORMATTING_INCLUDE_SECONDS = 'configure-advanced-formatting-include-seconds';
export const CONFIGURE_ADVANCED_FORMATTING_TIME_PREVIEW = 'configure-advanced-formatting-time-preview';
export const CONFIGURE_ADVANCED_FORMATTING_DATE_STYLE = 'configure-advanced-formatting-date-style';
export const CONFIGURE_ADVANCED_FORMATTING_DATE_SEPARATOR = 'configure-advanced-formatting-date-separator';
export const CONFIGURE_ADVANCED_FORMATTING_COMPONENT_ORDER = 'configure-advanced-formatting-component-order';
export const CONFIGURE_ADVANCED_FORMATTING_YEAR_FORMAT = 'configure-advanced-formatting-year-format';
export const CONFIGURE_ADVANCED_FORMATTING_DATE_SHORT_PREVIEW = 'configure-advanced-formatting-date-short-preview';
export const CONFIGURE_ADVANCED_FORMATTING_DATE_LONG_FORMAT = 'configure-advanced-formatting-date-long-format';
export const CONFIGURE_ADVANCED_FORMATTING_DATE_LONG_PREVIEW = 'configure-advanced-formatting-date-long-preview';
export const CONFIGURE_ADVANCED_SECURITY_BLOCK_REQUESTS_IP = 'configure-advanced-security-block-requests-ip';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_TO_BLOCK_USERS =
	'configure-advanced-security-allow-agent-to-block-users';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_TO_DOWNLOAD_CONVERSATIONS =
	'configure-advanced-security-allow-agents-to-download-conversations';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_TO_SHARE_FILES =
	'configure-advanced-security-allow-agents-to-share-files';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_TO_EDIT_ADDITIONAL_ATTRIBUTES =
	'configure-advanced-security-allow-agents-to-edit-additional-attributes';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_TO_SEND_TEMPLATE_MESSAGE =
	'configure-advanced-security-allow-agents-to-send-template-message';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_SUPERVISOR_ACCESS_TO_PATH_BUILDER =
	'configure-advanced-security-allow-supervisor-acces-to-path-builder';
export const CONFIGURE_ADVANCED_SECURITY_ENABLE_IP_ADDRESS_LOCATION_COLLECTION =
	'configure-advanced-security-enable-ip-address-location-collection';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_TO_VIEW_ALL_CONVERSATIONS =
	'configure-advanced-security-allow-agents-to-view-all-conversations';
export const CONFIGURE_ADVANCED_SECURITY_AGENTS_ACCESS_TO_HANDLED_CONVERSATIONS =
	'configure-advanced-security-agents-access-to-handled-conversations';
export const CONFIGURE_ADVANCED_SECURITY_AGENTS_ACCESS_TO_CONVERSATION_TO_ASSIGNED_CATEGORY =
	'configure-advanced-security-agents-access-to-conversation-to-assigned-category';
export const CONFIGURE_ADVANCED_SECURITY_AGENTS_ACCESS_TO_PREVIOUS_AND_NEW_CONVERSATIONS =
	'configure-advanced-security-agents-access-to-previous-and-new-conversations';
export const CONFIGURE_ADVANCED_SECURITY_ALLOW_AGENTS_ACCESS_TO_ADVANCED_FILTERS =
	'configure-advanced-security-allow-agents-access-to-advanced-filters';

// Deployment
export const CONFIGURE_DEPLOYMENT_WEBSITE_SHOPIFY_CHATBOT_DEPLOY =
	'configure-deployment-website-shopify-chatbot-deploy';
export const CONFIGURE_DEPLOYMENT_WEBSITE_TYPE = 'configure-deployment-website-type';
export const CONFIGURE_DEPLOYMENT_WEBSITE_CHAT_POSITION = 'configure-deployment-website-chat-position';
export const CONFIGURE_DEPLOYMENT_WEBSITE_DIRECT_LINK = 'configure-deployment-website-direct-link';
export const CONFIGURE_DEPLOYMENT_WEBSITE_RESTRICT_DOMAINS = 'configure-deployment-website-restrict-domains';
export const CONFIGURE_DEPLOYMENT_WEBSITE_DOMAINS_TO_RESTRICT = 'configure-deployment-website-domains-to-restrict';
export const CONFIGURE_DEPLOYMENT_WEBSITE_URL = 'configure-deployment-website-url';
export const CONFIGURE_DEPLOYMENT_WEBSITE_MAINTAINANCE_MODE = 'configure-deployment-website-maintainance-mode';
export const CONFIGURE_DEPLOYMENT_WEBSITE_BOT_SCRIPT = 'configure-deployment-website-bot-script';
export const CONFIGURE_CTA_MODAL_SCRIPT = 'configure-cta-modal-script';
export const CONFIGURE_DEPLOYMENT_WEBSITE_SAVE = 'configure-deployment-website-save';
export const CONFIGURE_DEPLOYMENT_WEBSITE_BOT_V2_SCRIPT_CHECK = 'configure-deployment-website-bot-v2-script-check';
export const CONFIGURE_DISPLAY_BOT_DEPLOYMENT_SETTINGS = 'configure-display-bot-deployment-settings';
export const CONFIGURE_DISPLAY_BOT_DISPLAY_SETTINGS = 'configure-display-bot-display-settings';
export const ANOTHER_BOT_DEPLOYED = 'another-bot-deployed';
export const ASSIGN_CHANNEL_TO_ALL_AGENTS_MODAL = 'assign-channel-to-all-agents-modal';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_PROMOTE_LINK_TO_BOT = 'configure-deployment-facebook-promote-link-to-bot';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_PROMOTE_EMBED_CODE = 'configure-deployment-facebook-promote-embed-code';
export const UPGRADE_MODAL = 'upgrade-modal';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_BUTTON_NAME = 'configure-deployment-facebook-menu-button-name';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_PATH = 'configure-deployment-facebook-menu-path';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_URL = 'configure-deployment-facebook-menu-url';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_SELECT_PATH = 'configure-deployment-facebook-menu-select-path';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_ENTER_URL = 'configure-deployment-facebook-menu-enter-url';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_ADD_BUTTON = 'configure-deployment-facebook-menu-add-button';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_MENU_SAVE = 'configure-deployment-facebook-menu-save';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_LOGIN = 'configure-deployment-login';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_SELECT_PAGE =
	'configure-deployment-facebook-connected-select-page';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_TRY_CHAT = 'configure-deployment-facebook-connected-try-chat';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_ENABLE_DISABLE =
	'configure-deployment-facebook-connected-enable-disable';
export const CONFIGURE_DEPLOYMENT_SHIPROCKET_DELETE = 'configure-deployment-shiprocket-delete';
export const CONFIGURE_DEPLOYMENT_SHIPROCKET_EDIT = 'configure-deployment-shiprocket-edit';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_REMOVE = 'configure-deployment-facebook-connected-remove';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_CONNECT = 'configure-deployment-facebook-connected-connect';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_ADD_PAGE = 'configure-deployment-facebook-connected-add-page';
export const CONFIGURE_DEPLOYMENT_FACEBOOK_CONNECTED_LOGOUT = 'configure-deployment-facebook-connected-logout';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_STORY_MENTION = 'configure-deployment-instagram-story-mention';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_TRIGGER_PATH = 'configure-deployment-instagram-trigger-path';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_SMART_RESPONSE = 'configure-deployment-instagram-smart-response';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_STORY_REPLY_PATH = 'configure-deployment-instagram-story-reply-path';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_STORY_REPLY_RESPONSE =
	'configure-deployment-instagram-story-reply-response';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_STORY_SETTINGS_SAVE = 'configure-deployment-instagram-story-settings-save';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CONTINUE_DEPLOYMENT = 'configure-deployment-instagram-continue-deployment';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CONNECTED_PAGE_CONNECT =
	'configure-deployment-instagram-connected-page-connect';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CONNECTED_PAGE_LOGOUT =
	'configure-deployment-instagram-connected-page-logout';
export const CHANGE_PAGE_HEADING = 'change-page-heading';
export const PAGE_HEADING_LOGOUT = 'page-heading-logout';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_REMOVE_CONNECTED_PAGE =
	'configure-deployment-instagram-remove-connected-page';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CONNECTED_PAGE_ENABLE =
	'configure-deployment-instagram-connected-page-enable';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CONNECTED_PAGE_DISABLE =
	'configure-deployment-instagram-connected-page-disable';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CHANGE_CONNECTED_PAGE =
	'configure-deployment-instagram-change-connected-page';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_CONNECTED_LOGOUT = 'configure-deployment-instagram-connected-logout';
export const REMOVE_PAGE_HEADING = 'remove-page-heading';
export const LOGOUT_POPUP_HEADING = 'logout-popup-heading';
export const LOGOUT_HEADING = 'logout-heading';
export const CONFIGURE_DEPLOYMENT_INSTAGRAM_LOGOUT = 'configure-deployment-instagram-logout';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_INBOUND_WEBHOOK_URL = 'configure-deployment-whatsapp-inbound-webhook-url';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_MESSAGE_WEBHOOK_URL = 'configure-deployment-whatsapp-message-webhook-url';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_PHONE_NUMBER = 'configure-deployment-whatsapp-phone-number';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_JSON_WEB_TOKEN = 'configure-deployment-whatsapp-json-web-token';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_CLIENT_URL = 'configure-deployment-whatsapp-client-url';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_CLOUD_LOGOUT = 'configure-deployment-whatsapp-cloud-logout';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_CLOUD_REMOVE_CONNECTED_NUMBER =
	'configure-deployment-whatsapp-cloud-remove-connected-number';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_CLOUD_CONNECTED_NUMBER_CONNECT =
	'configure-deployment-whatsapp-cloud-connected-number-connect';
export const PROVIDER_SWITCH_HEADING = 'provider-switch-heading';
export const CONFIGURE_DEPLOYMENT_DIALOG_INBOUND_WEBHOOK_URL = 'configure-deployment-dialog-inbound-webhook-url';
export const CONFIGURE_DEPLOYMENT_DIALOG_PHONE_NUMBER = 'configure-deployment-dialog-phone-number';
export const CONFIGURE_DEPLOYMENT_DIALOG_API_KEY = 'configure-deployment-dialog-api-key';
export const CONFIGURE_DEPLOYMENT_DIALOG_CLIENT_URL = 'configure-deployment-dialog-client-url';
export const CONFIGURE_DEPLOYMENT_DIALOG_STATUS_WEBHOOK_URL = 'configure-deployment-dialog-status-webhook-url';
export const DIALOG_360 = 'dailog-360';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_PROVIDER = 'configure-deployment-whatsapp-provider';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_SAVE = 'configure-deployment-whatsapp-save';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_DISABLE = 'configure-deployment-whatsapp-disable';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_PROMOTE_PREFILLED_MESSAGE =
	'configure-deployment-whatsapp-promote-prefilled-message';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_PROMOTE_LINK_TO_BOT = 'configure-deployment-whatsapp-promote-link-to-bot';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_PROMOTE_DOWNLOAD_QR_CODE =
	'configure-deployment-whatsapp-promote-download-qr-code';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_PROMOTE_WIDGET_CODE = 'configure-deployment-whatsapp-promote-widget-code';
export const CONFIGURE_DEPLOYMENT_KALEYRA_INBOUND_WEBHOOK_URL = 'configure-deployment-kaleyra-inbound-webhook-url';
export const CONFIGURE_DEPLOYMENT_KALEYRA_MESSAGE_WEBHOOK_URL = 'configure-deployment-kaleyra-message-webhook-url';
export const CONFIGURE_DEPLOYMENT_KALEYRA_PHONE_NUMBER = 'configure-deployment-kaleyra-phone-number';
export const CONFIGURE_DEPLOYMENT_KALEYRA_API_KEY = 'configure-deployment-kaleyra-api-key';
export const CONFIGURE_DEPLOYMENT_KALEYRA_SID = 'configure-deployment-kaleyra-sid';
export const CONFIGURE_DEPLOYMENT_KALEYRA_CLIENT_URL = 'configure-deployment-kaleyra-client-url';
export const CONFIGURE_DEPLOYMENT_AIRTEL_INBOUND_WEBHOOK_URL = 'configure-deployment-airtel-inbound-webhook-url';
export const CONFIGURE_DEPLOYMENT_AIRTEL_MESSAGE_WEBHOOK_URL = 'configure-deployment-airtel-message-webhook-url';
export const CONFIGURE_DEPLOYMENT_AIRTEL_USERNAME = 'configure-deployment-airtel-username';
export const CONFIGURE_DEPLOYMENT_AIRTEL_SECRET = 'configure-deployment-airtel-secret';
export const CONFIGURE_DEPLOYMENT_AIRTEL_PHONE_NUMBER = 'configure-deployment-airtel-phone-number';
export const CONFIGURE_DEPLOYMENT_AIRTEL_WABA_ID = 'configure-deployment-airtel-waba-id';
export const CONFIGURE_DEPLOYMENT_AIRTEL_CUSTOMER_ID = 'configure-deployment-airtel-customer-id';
export const CONFIGURE_DEPLOYMENT_AIRTEL_SUB_ACCOUNT_ID = 'configure-deployment-airtel-sub-account-id';
export const CONFIGURE_DEPLOYMENT_AIRTEL_CLIENT_URL = 'configure-deployment-airtel-client-url';
export const CONFIGURE_DEPLOYMENT_NEXMO_AUTHENTICATION_MODE = 'configure-deployment-nexmo-authentication-mode';
export const CONFIGURE_DEPLOYMENT_NEXMO_INBOUND_WEBHOOK_URL = 'configure-deployment-nexmo-inbound-webhook-url';
export const CONFIGURE_DEPLOYMENT_NEXMO_MESSAGE_WEBHOOK_URL = 'configure-deployment-nexmo-message-webhook-url';
export const CONFIGURE_DEPLOYMENT_NEXMO_PHONE_NUMBER = 'configure-deployment-nexmo-phone-number';
export const CONFIGURE_DEPLOYMENT_NEXMO_JSON_WEB_TOKEN = 'configure-deployment-nexmo-json-web-token';
export const CONFIGURE_DEPLOYMENT_NEXMO_APPLICATION_ID = 'configure-deployment-nexmo-application-id';
export const CONFIGURE_DEPLOYMENT_NEXMO_PRIVATE_KEY = 'configure-deployment-nexmo-private-key';
export const CONFIGURE_DEPLOYMENT_NEXMO_CLIENT_URL = 'configure-deployment-nexmo-client-url';
export const CONFIGURE_DEPLOYMENT_TWILIO_INBOUND_WEBHOOK_URL = 'configure-deployment-twilio-inbound-webhook-url';
export const CONFIGURE_DEPLOYMENT_TWILIO_MESSAGE_WEBHOOK_URL = 'configure-deployment-twilio-message-webhook-url';
export const CONFIGURE_DEPLOYMENT_TWILIO_PHONE_NUMBER = 'configure-deployment-twilio-phone-number';
export const CONFIGURE_DEPLOYMENT_TWILIO_ACCOUNT_SID = 'configure-deployment-twilio-account-sid';
export const CONFIGURE_DEPLOYMENT_TWILIO_AUTHENTICATION_TOKEN = 'configure-deployment-twilio-authentication-token';
export const CONFIGURE_DEPLOYMENT_TWILIO_CLIENT_URL = 'configure-deployment-twilio-client-url';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_NATIVE_WEBHOOK_URL = 'configure-deployment-whatsapp-native-webhook-url';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_NATIVE_USER_NAME = 'configure-deployment-whatsapp-native-user-name';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_NATIVE_PASSWORD = 'configure-deployment-whatsapp-native-password';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_NATIVE_CLIENT_URL = 'configure-deployment-whatsapp-client-url';
export const EXIT = 'exit';
export const CONFIGURE_DEPLOYMENT_GBM_AGENT_ID = 'configure-deployment-gbm-agent-id';
export const CONFIGURE_DEPLOYMENT_GBM_SAVE = 'configure-deployment-gbm-save';
export const CONFIGURE_DEPLOYMENT_GBM_DISABLE = 'configure-deployment-gbm-disable';
export const CONFIGURE_DEPLOYMENT_LINE_ACCESS_TOKEN = 'configure-deployment-line-access-token';
export const CONFIGURE_DEPLOYMENT_LINE_WEB_APP_URL = 'configure-deployment-line-web-app-url';
export const CONFIGURE_DEPLOYMENT_LINE_SAVE = 'configure-deployment-line-save';
export const CONFIGURE_DEPLOYMENT_TEAM_ENDPOINT_URL = 'configure-deployment-team-endpoint-url';
export const CONFIGURE_DEPLOYMENT_TEAM_APP_ID = 'configure-deployment-team-app-id';
export const CONFIGURE_DEPLOYMENT_TEAM_APP_SECRET = 'configure-deployment-team-app-secret';
export const CONFIGURE_DEPLOYMENT_TEAM_SAVE = 'configure-deployment-team-save';
export const CONFIGURE_DEPLOYMENT_MOBILE_ACCESS_TOKEN = 'configure-deployment-mobile-access-token';
export const CONFIGURE_DEPLOYMENT_SKYPE_ENDPOINT_URL = 'configure-deployment-skype-endpoint-url';
export const CONFIGURE_DEPLOYMENT_SKYPE_APP_ID = 'configure-deployment-skype-app-id';
export const CONFIGURE_DEPLOYMENT_SKYPE_APP_SECRET = 'configure-deployment-skype-app-secret';
export const CONFIGURE_DEPLOYMENT_SKYPE_SAVE = 'configure-deployment-skype-save';
export const CONFIGURE_DEPLOYMENT_SKYPE_BUSINESS_ENDPOINT_URL = 'configure-deployment-skype-business-endpoint-url';
export const CONFIGURE_DEPLOYMENT_SKYPE_BUSINESS_APP_ID = 'configure-deployment-skype-business-app-id';
export const CONFIGURE_DEPLOYMENT_SKYPE_BUSINESS_APP_SECRET = 'configure-deployment-skype-business-app-secret';
export const CONFIGURE_DEPLOYMENT_SKYPE_BUSINESS_SAVE = 'configure-deployment-skype-business-save';
export const CONFIGURE_DEPLOYMENT_SLACK_REDIRECT_URL = 'configure-deployment-slack-redirect-url';
export const CONFIGURE_DEPLOYMENT_SLACK_REQUEST_URL = 'configure-deployment-slack-request-url';
export const CONFIGURE_DEPLOYMENT_SLACK_CLIENT_ID = 'configure-deployment-slack-client-id';
export const CONFIGURE_DEPLOYMENT_SLACK_CLIENT_SECRET = 'configure-deployment-slack-client-secret';
export const CONFIGURE_DEPLOYMENT_SLACK_VERIFICATION_TOKEN = 'configure-deployment-slack-verification-token';
export const CONFIGURE_DEPLOYMENT_SLACK_ADD = 'configure-deployment-slack-add';
export const CONFIGURE_DEPLOYMENT_SLACK_SAVE = 'configure-deployment-slack-save';
export const CONFIGURE_DEPLOYMENT_TELEGRAM_ACCESS_TOKEN = 'configure-deployment-telegram-access-token';
export const CONFIGURE_DEPLOYMENT_TELEGRAM_SAVE = 'configure-deployment-telegram-save';
export const CONFIGURE_DEPLOYMENT_TELEGRAM_DISABLE = 'configure-deployment-telegram-disable';
export const CONFIGURE_DEPLOYMENT_TWITTER_USER_NAME = 'configure-deployment-twitter-user-name';
export const CONFIGURE_DEPLOYMENT_TWITTER_API_KEY = 'configure-deployment-twitter-api-key';
export const CONFIGURE_DEPLOYMENT_TWITTER_API_SECRET_KEY = 'configure-deployment-twitter-api-secret-key';
export const CONFIGURE_DEPLOYMENT_TWITTER_ACCESS_TOKEN = 'configure-deployment-twitter-access-token';
export const CONFIGURE_DEPLOYMENT_TWITTER_ACCESS_TOKEN_SECRET = 'configure-deployment-twitter-access-token-secret';
export const CONFIGURE_DEPLOYMENT_TWITTER_DEV_ENV_LABEL = 'configure-deployment-twitter-dev-env-label';
export const CONFIGURE_DEPLOYMENT_TWITTER_ACCOUNT_ACTIVITY_API_TIER =
	'configure-deployment-twitter-account-activity-api-tier';
export const CONFIGURE_DEPLOYMENT_TWITTER_SAVE = 'configure-deployment-twitter-save';
export const CONFIGURE_DEPLOYMENT_WECHAT_WEBHOOK_URL = 'configure-deployment-wechat-webhook-url';
export const CONFIGURE_DEPLOYMENT_WECHAT_TOKEN = 'configure-deployment-wechat-token';
export const CONFIGURE_DEPLOYMENT_WECHAT_APP_ID = 'configure-deployment-wechat-app-id';
export const CONFIGURE_DEPLOYMENT_WECHAT_APP_SECRET = 'configure-deployment-wechat-app-secret';
export const CONFIGURE_DEPLOYMENT_WECHAT_SAVE = 'configure-deployment-wechat-save';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_DELETE_PROFILE =
	'configure-deployment-whatsapp-business-delete-profile';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_ABOUT = 'configure-deployment-whatsapp-business-about';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_DESCRIPTION = 'configure-deployment-whatsapp-business-description';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_ADDRESS = 'configure-deployment-whatsapp-business-address';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_INDUSTRY = 'configure-deployment-whatsapp-business-industry';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_EMAIL = 'configure-deployment-whatsapp-business-email';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_WEBSITE1 = 'configure-deployment-whatsapp-business-website1';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_WEBSITE2 = 'configure-deployment-whatsapp-business-website2';
export const CONFIGURE_DEPLOYMENT_WHATSAPP_BUSINESS_SAVE = 'configure-deployment-whatsapp-business-save';
export const CHECKBOX = 'checkbox';

// Integrations
export const CONFIGURE_INTEGRATIONS_SHOPIFY_CARD = 'configure-integrations-shopify-card';
export const CONFIGURE_INTEGRATIONS_WOOCOMMERCE_CARD = 'configure-integrations-woocommerce-card';
export const CONFIGURE_INTEGRATIONS_SHIPROCKET_CARD = 'configure-integrations-shiprocket-card';
export const CONFIGURE_INTEGRATIONS_RAZORPAY_CARD = 'configure-integrations-razorpay-card';
export const CONFIGURE_INTEGRATIONS_GOOGLE_ANALYTICS_CARD = 'configure-integrations-google-analytics-card';
export const CONFIGURE_INTEGRATIONS_GOOGLE_TRANSLATE_CARD = 'configure-integrations-google-translate-card';
export const CONFIGURE_INTEGRATIONS_GORGIAS_CARD = 'configure-integrations-gorgias-card';
export const CONFIGURE_INTEGRATIONS_GORGIAS_ACCOUNT_NAME = 'configure-integrations-gorgias-account-name';
export const CONFIGURE_INTEGRATIONS_ZENDESK_CARD = 'configure-integrations-zendesk-card';
export const CONFIGURE_INTEGRATIONS_SALESFORCE_CARD = 'configure-integrations-salesforce-card';
export const CONFIGURE_INTEGRATIONS_ZENDESK_ACCOUNT_NAME = 'configure-integrations-zendesk-account-name';
export const CONFIGURE_INTEGRATIONS_FRESHDESK_CARD = 'configure-integrations-freshdesk-card';
export const CONFIGURE_INTEGRATIONS_FRESHDESK_ACCOUNT_NAME = 'configure-integrations-freshdesk-account-name';
export const CONFIGURE_INTEGRATIONS_GOOGLE_ADMIN_CARD = 'configure-integrations-google-admin-card';
export const CONFIGURE_INTEGRATIONS_GOOGLE_ADMIN_ACCOUNT_NAME = 'configure-integrations-google-admin-account-name';
export const CONFIGURE_INTEGRATIONS_SMS_CARD = 'configure-integrations-sms-card';
export const CONFIGURE_INTEGRATIONS_SMS_ACCOUNT_NAME = 'configure-integrations-sms-account-name';
export const CONFIGURE_INTEGRATIONS_TRANSLATE_ACCOUNT_NAME = 'configure-integrations-translate-account-name';
export const CONFIGURE_INTEGRATIONS_RAZORPAY_ACCOUNT_NAME = 'configure-integrations-razorpay-account-name';
export const CONFIGURE_INTEGRATIONS_TRANSLATE_SAVE = 'configure-integrations-translate-save';
export const CONFIGURE_INTEGRATIONS_RAZORPAY_SAVE = 'configure-integrations-razorpay-save';
export const CONFIGURE_INTEGRATIONS_RAZORPAY_UNLINK = 'configure-integrations-razorpay-unlink';
export const CONFIGURE_INTEGRATIONS_ANALYTICS_ACCOUNT_NAME = 'configure-integrations-analytics-account-name';
export const CONFIGURE_INTEGRATIONS_ANALYTICS_CONFIGURED = 'configure-integrations-analytics-configured';
export const CONFIGURE_INTEGRATIONS_ANALYTICS_SAVE = 'configure-integrations-analytics-save';
export const SAVE_CHANGES = 'save-changes';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_SAVE = 'configure-integrations-shopify-save';
export const STORE_PRODUCTS_SAVE = 'store-products-save';
export const CONVERSION_TRIGGERS_SAVE = 'conversion-triggers-save';
export const SETUP_SAVE = 'setup-save';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_STORE = 'configure-integrations-shopify-store';
export const CHECK_BOT_STORE_MAPPING = 'check-bot-store-mapping';
export const CONTACT_SUPPORT = 'contact-support';
export const ADD_ACTION_BUTTON = 'add-action-button';
export const ADD_PAGE_URL = 'add-page-url';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_SETTINGS_TRIGGER_ANALYTICS_ENABLED =
	'configure-integrations-shopify-settings-trigger-analytics-enabled';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCTS_DISCOVERY_ENABLED =
	'configure-integrations-shopify-products-discovery-enabled';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCTS_DISCOVERY_LINK =
	'configure-integrations-shopify-products-discovery-link';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCT_DISCOVERY_SIMILAR_PRODUCTS =
	'configure-integrations-shopify-product-discovery-show-similar-products';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCT_DISCOVERY_SHOW_OUT_OF_STOCK =
	'configure-integrations-shopify-product-discovery-show-out-of-stock';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCT_DISCOVERY_SORTING_ORDER =
	'configure-integrations-shopify-product-discovery-sorting-order';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_ENABLE_PRODUCT_DISCOVERY_CONFIRMATION_BUTTON =
	'configure-integrations-shopify-enable-product-discovery-confirmation-button';
export const ENABLE_SHOPIFY_SEGMENTS = 'enable-shopify-segments';
export const ENABLE_SHOPIFY_SEGMENTS_LABEL = 'enable-shopify-segments-label';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCT_DISCOVERY_SELECT_CATEGORY =
	'configure-integrations-shopify-product-discovery-select-category';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCT_DISCOVERY_CATEGORY_ACCORDION =
	'configure-integrations-shopify-product-discovery-category-accordion';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_PRODUCT_DISCOVERY_ADD_NEW_CATEGORY_BUTTON =
	'configure-integrations-shopify-product-discovery-add-new-category-button';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_SETTINGS_WA_NOTIFICATION_ENABLED =
	'configure-integrations-shopify-settings-wa-notification-enabled';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_SETTINGS_WA_NOTIFICATION_PATH =
	'configure-integrations-shopify-settings-wa-notification-path';
export const CONFIGURE_INTEGRATIONS_SHOPIFY_NEW_TRIGGER = 'configure-integrations-shopify-new-trigger';
export const CONFIGURE_INTEGRATIONS_SHIPROCKET_ADD_CHANNEL_BUTTON = 'configure-deployment-login';
export const CONFIGURE_DEPLOYMENT_WA_CLOUD_NUMBER_CHANGE = 'configure-deployment-wa-cloud-number-change';
export const BACK_BUTTON_IN_SHOPIFY_ONEVIEW_PAGE = 'back-button-in-shopify-oneview-page';
export const BACK_BUTTON_IN_SHOPIFY_INTEGRATION_PAGE = 'back-button-in-shopify-integration-page';
export const CONFIGURE_CALLER_ID_EXOTEL = 'configure-caller-id-exotel';
export const CONFIGURE_ESENSEGPT_INTEGRATION = 'configure-esensegpt-integration';
export const CONFIGURE_ESENSEGPT_INTEGRATION_ACCOUNT_NAME = 'configure-esensegpt-integration-account-name';
export const CONFIGURE_INTEGRATIONS_ESENSE_GPT_SAVE = 'configure-integrations-esensegpt-save';

// Broadcast
export const BROADCAST_VIEW_BROADCAST_FAILED_USERS_RETRY_BUTTON = 'broadcast-view-broadcast-rety-button';

// Broadcast --> Message Templates
export const CREATE_MESSAGE_TEMPLATES_CATEGORY = 'create-message-templates-category';
export const CREATE_MESSAGE_TEMPLATES_TEMPLATE_NAME = 'create-message-templates-template-name';
export const CREATE_MESSAGE_TEMPLATES_LANGUAGE = 'create-message-templates-language';
export const CREATE_MESSAGE_TEMPLATES_TARGETED_CHANNELS = 'create-message-templates-targeted-channels';
export const CREATE_MESSAGE_TEMPLATES_TEMPLATE_TYPE = 'create-message-templates-template-type';
export const CREATE_MESSAGE_TEMPLATES_TEMPLATE_BODY = 'create-message-templates-template-body';
export const CREATE_MESSAGE_TEMPLATES_HEADER_TYPE = 'create-message-templates-header_type';
export const CREATE_MESSAGE_TEMPLATES_TEXT_INPUT = 'create-message-templates-text-input';
export const CREATE_MESSAGE_TEMPLATES_FOOTER = 'create-message-templates-footer';
export const CREATE_MESSAGE_TEMPLATES_BUTTON_TYPE = 'create-message-templates-button-type';
export const CREATE_MESSAGE_TEMPLATES_BUTTON_LABEL = 'create-message-templates-button-label';
export const CREATE_MESSAGE_TEMPLATES_BUTTON_VALUE = 'create-message-templates-button-value';
export const CREATE_MESSAGE_TEMPLATES_REMOVE_BUTTON = 'create-message-templates-remove-button';
export const CREATE_MESSAGE_TEMPLATES_FORM_SUBMIT_BUTTON = 'create-message-templates-form-submit-button';
export const CREATE_MESSAGE_TEMPLATES_PREVIEW_VARIABLES_INPUT = 'create-message-templates-preview-variables-input';
export const CREATE_MESSAGE_TEMPLATES_OPT_OUT_BUTTON = 'create-message-templates-opt-out-button';
export const CREATE_MESSAGE_TEMPLATES_CALCULATE_ANALYTICS_BUTTON =
	'create-message-templates-calculate-analytics-button';
export const CREATE_MESSAGE_TEMPLATES_PREVIEW_VARIABLES_URL_BUTTON_DISABLED_INPUT =
	'create-message-templates-preview-variables-url-button-disabled-input';
export const CREATE_MESSAGE_TEMPLATES_PREVIEW_VARIABLES_URL_BUTTON_VARIABLE_INPUT =
	'create-message-templates-preview-variables-url-button-variable-input';
export const CREATE_MESSAGE_TEMPLATES_FORM_HEADER_TYPE = 'create-message-templates-form-header-type';
export const CREATE_MESSAGE_TEMPLATES_PREVIEW_BOX = 'create-message-templates-preview-box';

// WA Commerce
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_CREATE = 'configure-deployment-wa-commerce-catalog-create';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_CONNECT = 'configure-deployment-wa-commerce-catalog-connect';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_SAVE = 'configure-deployment-wa-commerce-catalog-save';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_NEXT = 'configure-deployment-wa-commerce-catalog-next';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_PREVIOUS = 'configure-deployment-wa-commerce-catalog-previous';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_LINK = 'configure-deployment-wa-commerce-catalog-link';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_CHANGE = 'configure-deployment-wa-commerce-catalog-change';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_TEST = 'configure-deployment-wa-commerce-catalog-create';
export const CONFIGURE_DEPLOYMENT_WA_COMMERCE_CATALOG_LOGOUT = 'configure-deployment-wa-commerce-catalog-logout';

// Compliance Info
export const COMPLIANCE_INFO_ENTITY_NAME_INPUT = 'compliance-info-entity-name-input';
export const COMPLIANCE_INFO_ENTITY_TYPE_INPUT = 'compliance-info-entity-type-input';
export const COMPLIANCE_INFO_ENTITY_TYPE_CUSTOM_INPUT = 'compliance-info-entity-type-custom-input';
export const COMPLIANCE_INFO_CUSTOMER_CARE_EMAIL_INPUT = 'compliance-info-customer-care-email-input';
export const COMPLIANCE_INFO_CUSTOMER_CARE_LANDLINE_INPUT = 'compliance-info-customer-care-landline-input';
export const COMPLIANCE_INFO_CUSTOMER_CARE_MOBILE_INPUT = 'compliance-info-customer-care-mobile-input';
export const COMPLIANCE_INFO_GRIEVANCE_OFFICER_EMAIL_INPUT = 'compliance-info-grievance-officer-email-input';
export const COMPLIANCE_INFO_GRIEVANCE_OFFICER_NAME_INPUT = 'compliance-info-grievance-officer-name-input';
export const COMPLIANCE_INFO_GRIEVANCE_OFFICER_LANDLINE_INPUT = 'compliance-info-grievance-officer-landline-input';
export const COMPLIANCE_INFO_GRIEVANCE_OFFICER_MOBILE_INPUT = 'compliance-info-grievance-officer-mobile-input';
export const COMPLIANCE_INFO_FORM_SUBMIT_BUTTON = 'compliance-info-form-submit-button';

export const FORM_SELECT_WITH_COLOR_LABEL_FAQ = 'form-select-with-color-label-faq';

// Navigation Button
export const OVERVIEW = 'overview';
export const MESSAGES = 'messages';
export const BUILDER = 'builder';
export const TRAIN = 'train';
export const CONFIGURE = 'configure';
export const USERS = 'users';
export const INTEGRATIONS = 'integrations';
export const ANALYTICS = 'analytics';
export const BROADCAST = 'broadcast';
export const QUICK_START_GUIDE = 'quick-start-guide';
export const STORE = 'store';
export const BACK_TO_HOME = 'back-to-home';
export const TEAM = 'team';
export const WHATSAPP = 'whatsapp-manager-navigation-icon';
export const NAVIGATIONAL_HELPER_BOT_ACTIONS = 'navigational-helper-bot-actions';
export const NAVIGATIONAL_HELPER_LIVECHAT_ACTIONS = 'navigational-helper-livechat-actions';
export const NAVIGATIONAL_INDICATOR = 'navigational-indicator';

export const MESSAGE_OVERFLOW_ICON = 'message-overflow-icon';
export const LAYOUT_CONTENT = 'layout-content';

// Message Section
export const USER_LIST_BLOCK = 'users-list-block';
export const CONVERSATION_BLOCK = 'conversation-block';
export const USER_DETAILS_BLOCK = 'user-details-block';
export const TIME_STAMP = 'time-stamp';
export const NEW_MESSAGE_INDICATOR = 'new-message-indicator';
export const CONVERSATION_BOX = 'conversation-box';
export const COlLAPSE_BUTTON = 'collapse-button';
export const GO_TO_CONVERSATION = 'go-to-conversation';
export const SHARE_PRODUCTS = 'share-products';
export const MESSAGE_COUNT = 'message-count';
export const GENERATE_SUMMARY = 'generate-summary';
export const COPY_SUMMARY = 'copy-summary';
export const COPY_EXPLANATION = 'copy-explanation';
export const RETRY_EXPLANATION = 'retry-explanation';
export const SIMPLIFIED_VIEW_OPTION = 'simplified-view-option';

// Subscription Details
export const LIVE_CHAT_LICENSES = 'live-chat-licenses';
export const NEXT_BILLING_DATE = 'next-billing-date';

// Bot
export const BOT_NAME = 'bot-name';
export const CREATE_A_BOT = 'create-a-bot';
export const BOT_CARD = 'bot-card';

// Shopify
export const CREATE_ACCOUNT = ' bot-setup-continue';
export const LAUNCH_ICON_UPLOAD = 'launch-icon-upload';
export const WEEK_DAYS_WITH_INDEX = 'week-days-';
export const WEB_VIEW_NODE_PATH = 'wa-payment-node-path';

// Users
export const IMPORT_USERS_XLSX_SAMPLE_FILE = 'import-users-xlsx-sample-file';
export const IMPORT_USERS_CSV_SAMPLE_FILE = 'import-users-csv-sample-file';
export const IMPORT_USERS_CHANNEL_DROPDOWN = 'import-users-channel-dropdown';
export const IMPORT_USERS_COUNTRY_DROPDOWN = 'import-users-country-dropdown';
export const CONTACTS_SECONDARY_NAV = 'users-secondary-nav-contacts';
export const USERS_SECONDARY_NAV = 'users-secondary-nav-users';

// Template Message
export const ENABLE_STATUS_PACKET = 'enable-status-packet';
export const WAIT_TIME_FOR_STATUS_PACKET = 'wait-time-for-status-packet';
export const TYPE_OF_STATUS_PACKET = 'type-of-status-packet';
export const PATH_TO_TRIGGER = 'path-to-trigger';
export const GENERATED_CURL = 'generated-curl';
// Broadcast-Campaigns
export const TYPE_BUTTONS = 'type-buttons';
export const SCHEDULE_BUTTONS = 'schedule-buttons';
export const DELIVERY_ON_BUTTONS = 'delivery-on-buttons';
export const CREATE_STEP_CAMPAIGN = 'create-step-campaign';
// WA Payment
export const WA_PAYMENT_CONFIG_NAME = 'wa-payment-config-name';
export const WA_PAYMENT_TYPE = 'wa-payment-type';
export const WA_PAYMENT_NODE_ACCOUNT = 'wa-payment-node-account';
export const WA_PAYMENT_NODE_BODY_TEXT = 'wa-payment-node-body-text';
export const WA_PAYMENT_NODE_ORDER_TYPE = 'wa-payment-node-order-type';
export const WA_PAYMENT_NODE_CATALOG_ID = 'wa-payment-node-catalog-id';
export const WA_PAYMENT_NODE_ITEMS = 'wa-payment-node-items';
export const WA_PAYMENT_NODE_EXPIRE_BY = 'wa-payment-node-expire-by';
export const WA_PAYMENT_NODE_EXPIRE_DESCRIPTION = 'wa-payment-node-expire-description';
export const WA_PAYMENT_NODE_CUSTOMS_OPTION = 'wa-payment-node-customs-option';
export const WA_PAYMENT_NODE_CUSTOMS_AMOUNT = 'wa-payment-node-customs-amount';
export const WA_PAYMENT_NODE_CUSTOMS_ATTRIBUTE = 'wa-payment-node-customs-attribute';
export const WA_PAYMENT_NODE_CUSTOMS_DESCRIPTION = 'wa-payment-node-customs-description';
export const WA_PAYMENT_NODE_ADDITIONAL_DESCRIPTION = 'wa-payment-node-additional-description';
export const WA_PAYMENT_NODE_PATH = 'wa-payment-node-path';
export const WA_PAYMENT_NODE_FOOTER_TEXT = 'wa-payment-node-footer-text';
export const WA_PAYMENT_NODE_STOP_FLOW = 'wa-payment-node-stop-flow';
export const WA_PAYMENT_NODE_CONTINUE_FLOW = 'wa-payment-node-continue-flow';
export const WA_PAYMENT_NODE_NOT_SEND_MESSAGE = 'wa-payment-node-not-send-message';
export const WA_PAYMENT_NODE_SEND_MESSAGE = 'wa-payment-node-send-message';
export const WA_PAYMENT_NODE_SEND_SMO = 'wa-payment-node-send-smo';
export const WA_PAYMENT_NODE_ERROR_MESSAGE = 'wa-payment-node-error-message';
export const WA_PAYMENT_NODE_CONTINUE_ERROR_MESSAGE = 'wa-payment-node-continue-error-message';
export const WA_PAYMENT_NODE_OPTION_TITLE = 'wa-payment-node-option-title';
export const WA_PAYMENT_NODE_OPTION_CONNECTION = 'wa-payment-node-option-connection';
export const WA_PAYMENT_NODE_REFERENCE_ID = 'wa-payment-node-reference-id';
// WA Flows
export const WA_FLOW_NAME = 'wa-flow-name';
export const WA_FLOW_BODY = 'wa-flow-body';
export const WA_TEST_FLOW_BODY = 'wa-test-flow-body';
export const WA_FLOW_BUTTON = 'wa-flow-button';
export const WA_TEST_FLOW_BUTTON = 'wa-test-flow-button';
export const WA_FLOW_PATH_TO_TRIGGER = 'wa-flow-path-to-trigger';
export const WA_FLOW_ACTION_DATA = 'wa-flow-action-data';
export const WA_TEST_FLOW_ACTION_DATA = 'wa-test-flow-action-data';
// ENGT Inline Editor
export const ENGT_INLINE_EDITOR_CANCEL_BUTTON = 'engt-inline-editor-cancel-button';
export const ENGT_INLINE_EDITOR_CONFIRM_BUTTON = 'engt-inline-editor-confirm-button';
export const ENGT_INLINE_EDITOR_FEEDBACK_BUTTON = 'engt-inline-editor-feedback-button';
// AD Details
export const AD_DETAILS_NEXT = 'ad-details-next';
export const AD_DETAILS_LOGOUT = 'ad-details-logout';
export const AD_DETAILS_CONNECT = 'ad-details-connect';
export const AD_DETAILS_PREVIOUS = 'ad-details-previous';
