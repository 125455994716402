import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from 'components/UI/Loader/Loader';

import { ENGATI_ROLES } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';

import { engagementTriggersEntilement } from 'store/App/Shopify/selectors';
import { redirectToLoginAction } from 'store/App/Token';
import { isAcceleratorEcomSelector } from 'store/App/User';
import {
	isCommonWhatsappConfiguredAsProvider,
	isDialog360ConfiguredAsProvider,
	isEmailTemplatesEnabledSelector,
	isShopifyEnabledAcc,
	isShopifySegmentsEnabled,
	isWhatsAppCloudConfiguredAsProvider,
	isWhatsAppConfiguredSelector,
	isWhatsAppPaymentEnabledSelector,
} from 'store/App/User/selectors';
import {
	isFbInboxCommentsEnabledSelector,
	isInstaInboxCommentsEnabledSelector,
} from 'store/Message/PostListAndOverview/selectors';
import { RootState } from 'store/rootReducer';

import { EmptyPageRoutesLinks } from 'router/EmptyPageRoutes';
import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

const { portalGetStartedSalesInfoLink: salesInfoLink } = EmptyPageRoutesLinks;

interface IProtectedRouteProps {
	children?: JSX.Element;
	path: string;
}

// Accessible without bots
export const COMMON_ROUTES_MAP: IObjProps = {
	'/': 'home',
	'/account-settings': '',
	'/account-settings/account': 'account',
	'/account-settings/notification': 'account_setting.notification',
	'/account-settings/password': '',
	'/account-settings/profile': '',
	'/account-settings/region': '',
	'/account-settings/usage-details': 'account',
	'/account-settings/allCustomerMAU': '',
	'/account-settings/usage-details/credits/recharge-history': 'credits_page',
	'/account-settings/usage-details/credits/consumption-history': 'credits_page',
	'/account-settings/usage-details/wallet/wallet-statement-history': 'wallet_page',
	'/account-settings/usage-details/wallet/wallet-consumption-history': 'wallet_page',
	'/account-settings/usage-breakdown': 'dashboard.enableCDP',
	'/account-settings/security': 'account',
	'/account-settings/billing': 'subscription_page',
	'/account-settings/billing/payment-history': 'pricing_page',
	'/account-settings/pricing': 'pricing_page',
	'/account-settings/theme': 'account_setting.theme',
	'/account-settings/team-members': 'account_setting.team',
	'/bots': 'mybots',
	'/no-access': '',
	'/not-found': '',
	'/get-started/usage-info': '',
	'/store/whatsapp-setup': '',
	'/store/store-setup': '',
	'/store/error': '',
	'/verify': '',
	'/verify-account': '',
	'/quick-navigator-ecommerce': '',
	'/quick-navigator-ecommerce/basic-setup': '',
	'/quick-navigator-ecommerce/feature-explorer': '',
	'/wa-phone': '',
};

// Accessible only with any particular bot
export const BOT_SPECIFIC_ROUTES: IObjProps = {
	'/analytics': 'dashboard',
	'/analytics/bot/channels': 'classicAnalyticsEnabled',
	'/analytics/bot/engagement': 'dashboard.engage',
	'/analytics/bot/response': 'dashboard.responses',
	'/analytics/gen-ai/query-analysis': 'dashboard.enableCDP',
	'/analytics/livechat/agents': 'classicAnalyticsEnabled',
	'/analytics/livechat/summary': 'dashboard.summary',
	'/analytics/livechat/agent-performance': 'dashboard.agentPerformance',
	'/analytics/livechat/agent-conversions': '',
	'/analytics/livechat/purchase-funnel': '',
	'/analytics/livechat/product-analytics': '',
	'/analytics/livechat/new-agent-performance': '',
	'/analytics/livechat/agent-productivity': 'dashboard.agentProductivity',
	'/analytics/livechat/agent-productivity/:userId': 'dashboard.agentProductivity',
	'/analytics/livechat/conversation-overview': 'dashboard.conversation',
	'/analytics/livechat/agent-assistance-feedback': 'dashboard.agentAssistanceFeedback',
	'/analytics/livechat/details': 'dashboard.enableCDP',
	'/analytics/livechat/sla-breaches': 'dashboard.enableCDP',
	'/analytics/livechat/feedback': 'dashboard.enableCDP',
	'/analytics/overview': 'dashboard',
	'/analytics/usage/button-click-analysis': 'dashboard.enableCDP',
	'/analytics/engagement/marketing-campaigns': 'dashboard.enableCDP',
	'/analytics/usage/platform-interactions': 'dashboard.enableCDP',
	'/analytics/livechat/automation-efficiency': 'dashboard.enableCDP',
	'/analytics/user/retention': 'dashboard.retain',
	'/integrations/api-logs': 'integrations.call_logs',
	'/bot/:id/overview': 'home',
	'/builder': 'pathBuilder',
	'/builder/:flowKey': 'pathBuilder',
	'/broadcast': 'broadcast',
	'/broadcast/help': 'broadcast',
	'/broadcast/published': 'broadcast',
	'/broadcast/execution': 'broadcast',
	'/broadcast/execution/broadcast': 'broadcast',
	'/broadcast/execution/ctwa': 'broadcast',
	'/broadcast/execution/campaign-automation': 'campaigns',
	'/broadcast/engagement': 'broadcast',
	'/broadcast/engagement/summary': 'broadcast',
	'/broadcast/engagement/broadcast': 'broadcast',
	'/broadcast/engagement/ctwa': 'broadcast',
	'/broadcast/conversion': 'broadcast',
	'/broadcast/published/view': 'broadcast',
	'/broadcast/pulse': 'broadcast',
	'/broadcast/report': 'broadcast',
	'/broadcast/scheduled': 'broadcast',
	'/broadcast/exportHistory': 'broadcast',
	'/broadcast/clickToWhatsappAds': 'broadcast',
	'/broadcast/create': 'broadcast',
	'/broadcast/campaigns': 'campaigns',
	'/broadcast/campaigns/view': 'campaigns',
	'/broadcast/draft': 'broadcast',
	'/broadcast/templates': 'broadcast',
	'/broadcast/email-templates': 'broadcast',
	'/store': 'store',
	'/store/segments': 'store',
	'/store/segments/:selectedTab': 'store',
	'/store/products': 'store',
	'/store/conversion': 'store',
	'/store/feature-configurator': 'store',
	'/store/setup': 'store',
	'/configure': 'configure',
	'/configure/advanced': 'configure.advanced',
	'/configure/advanced/voice': 'configure.advanced.voice',
	'/configure/advanced/formatting': 'configure.advanced.formatting',
	'/configure/advanced/security': 'configure.advanced.security',
	'/configure/attributes': 'configure.attributes',
	'/configure/publish': 'configure.publish',
	'/configure/version-history': 'configure.version_history',
	'/configure/attributes/change-history/:attrId': 'configure.attributes',
	'/configure/bot-details': 'configure.general',
	'/configure/deployment': 'configure.channels',
	'/configure/deployment/email': 'configure.channels',
	'/configure/deployment/facebook': 'configure.channels',
	'/configure/deployment/instagram': 'configure.channels',
	'/configure/deployment/gbm': 'configure.channels',
	'/configure/deployment/kik': 'configure.channels',
	'/configure/deployment/line': 'configure.channels',
	'/configure/deployment/mobile': 'configure.channels',
	'/configure/deployment/msTeams': 'configure.channels',
	'/configure/deployment/skype': 'configure.channels',
	'/configure/deployment/skypeBusiness': 'configure.channels',
	'/configure/deployment/slack': 'configure.channels',
	'/configure/deployment/telegram': 'configure.channels',
	'/configure/deployment/twitter': 'configure.channels',
	'/configure/deployment/viber': 'configure.channels',
	'/configure/deployment/weChat': 'configure.channels',
	'/configure/deployment/website': 'configure.channels',
	'/configure/deployment/whatsapp': 'configure.channels',
	'/configure/deployment/whatsapp/compliance': 'configure.channels',
	'/configure/deployment/whatsapp/businessProfile': 'configure.channels',
	'/configure/display': 'configure.web_display',
	'/configure/integrations': 'configure.integration',
	'/configure/integrations/google-analytics': 'configure.integration',
	'/configure/integrations/google-translate': 'configure.integration',
	'/configure/integrations/gorgias': 'configure.integration',
	'/configure/integrations/zendesk': 'configure.integration',
	'/configure/integrations/salesforce': 'configure.integration',
	'/configure/integrations/shiprocket': 'configure.integration',
	'/configure/integrations/razorpay': 'configure.integration',
	'/configure/integrations/woocommerce': 'configure.integration',
	'/configure/integrations/google-admin': 'configure.integration',
	'/configure/integrations/freshdesk': 'configure.integration',
	'/configure/integrations/sms': 'configure.integration',
	'/configure/integrations/shopify': 'configure.shopify',
	'/configure/integrations/esensegpt': 'configure.integration',
	'/configure/integrations/GPT': 'configure.integration',
	'/configure/languages': 'configure.advanced',
	'/configure/languages/keys-upload-history': 'configure.advanced',
	'/configure/live-chat': 'configure.live_chat',
	'/configure/external-livechat': 'is_external_livechat_provider',
	'/configure/live-chat/:setting': 'configure.live_chat',
	'/configure/overview': 'configure',
	'/configure/shopify': 'configure.shopify',
	'/configure/agent-productivity': 'configure.agent_productivity',
	'/integrations': 'integrations',
	'/integrations/cloud-integrations': 'integrations.cloud',
	'/integrations/platform-api': 'integrations.api',
	'/integrations/c2e-bridge': 'integrations.c2e',
	'/integrations/channel-logs': 'integrations.channel_logs',
	'/integrations/payment-logs': 'integrations.payment_logs',
	'/whatsApp': 'whatsApp',
	'/whatsApp/numbers': 'whatsApp.numbers',
	'/whatsApp/flows': 'whatsApp.flows',
	'/allmessages/': 'conversation',
	'/allmessages/:cid': 'conversation',
	'/messages': 'messages',
	'/messages/:tab/': 'messages',
	'/messages/:tab/:cid': 'messages',
	'/social-inbox/posts/facebook': '',
	'/social-inbox/posts/facebook/:cid': '',
	'/social-inbox/posts/instagram': '',
	'/social-inbox/posts/instagram/:cid': '',
	'/team': 'configure.team',
	'/train': 'train',
	'/train/cognitive-search': 'train.cognitive_search',
	'/train/cognitive-search/llm-settings': 'train.llm_settings',
	'/train/entities': 'entity',
	'/train/tabular-input': 'entity',
	'/train/faq': 'build',
	'/train/faq/configure-synonyms': 'advance_faq',
	'/train/faq/upload-history': 'build',
	'/train/responses/export-history': 'train',
	'/train/responses/file-upload-analytics': 'train',
	'/train/responses/api-error-history': 'train',
	'/train/overview': 'train',
	'/train/categories': 'train',
	'/train/settings': 'train',
	'/train/responses': 'train',
	'/users': 'connectUsers',
	'/users/home': 'connectUsers',
	'/contacts': 'contacts',
	'/contacts/list': 'contacts',
	'/users/contacts/export-history': 'contacts',
	'/users/contacts/import-history': 'contacts',
	'/users/contacts': 'contacts',
	'/users/home/list': 'connectUsers',
	'/users/home/segments': 'connectUsers',
	'/users/home/export-history': 'connectUsers',
	'/users/home/import-history': 'connectUsers',
	'/users/home/list/:globalUserId': 'connectUsers',
	'/quickGuide': '',
	'/quick-navigator-ecommerce': '',
	'/quick-navigator-ecommerce/basic-setup': '',
	'/quick-navigator-ecommerce/feature-explorer': '',
	'/accelerator-quick-guide-ecom': 'shopifyQuickNavigator',
	'/accelerator-quick-guide-ecom/basic-setup': 'shopifyQuickNavigator',
	'/accelerator-quick-guide-ecom/feature-explorer': 'shopifyQuickNavigator',
	'/wa-quick-guide': 'waQuickNavigator',
};

export const LEFT_PANEL_ACCESS_ROUTES: IObjProps = {
	...BOT_SPECIFIC_ROUTES,
	...COMMON_ROUTES_MAP,
};

export const ALL_ROUTES: Array<string> = Object.keys(LEFT_PANEL_ACCESS_ROUTES);
export const COMMON_ROUTES: Array<string> = Object.keys(COMMON_ROUTES_MAP);

export default function ProtectedRoute(props: IProtectedRouteProps) {
	const { children, path } = props;
	const csrfToken = localStorage.getItem('csrfToken');
	const inProgressOnboardingUser = localStorage.getItem('onboarding_in_progress') || '';
	const leftPanelsV2Data: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys || []);
	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);
	const userEmail: any = useSelector((state: RootState) => state.User.botAdmin.data.email);
	const shopifyProductDiscoveryEntitlement = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_shopify_product_discovery
	);
	const engagementTriggersEnabled = useSelector(engagementTriggersEntilement);
	const token = useSelector((state: RootState) => state.Token.token);
	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const isDialog360Configured = useSelector(isDialog360ConfiguredAsProvider);
	const isWhatsAppConfigured = useSelector(isWhatsAppConfiguredSelector);
	const isWaPaymentEnabled = useSelector(isWhatsAppPaymentEnabledSelector);
	const isInstaInboxCommentsEnabled: boolean = useSelector(isInstaInboxCommentsEnabledSelector);
	const isFbInboxCommentsEnabled: boolean = useSelector(isFbInboxCommentsEnabledSelector);
	const isSegmentsEnabled = useSelector(isShopifySegmentsEnabled);
	const isShopifyEnabled = useSelector(isShopifyEnabledAcc);
	const isAcceleratorEcom = useSelector(isAcceleratorEcomSelector);

	const isSystemSegmentsEnabled = isShopifyEnabled && isSegmentsEnabled;
	const isWhatsAppCloudConfigured = useSelector(isWhatsAppCloudConfiguredAsProvider);
	const isCommonWhatsappEnabled = useSelector(isCommonWhatsappConfiguredAsProvider);
	const isCreateMessageTemplatesV2Enabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_create_message_templates_v2
	);
	const isEmailTemplatesEnabled = useSelector(isEmailTemplatesEnabledSelector);

	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch<any>();
	const { noAccess, noPageFound } = NoColumnRouteLinks;
	const { socialInboxInstagram, socialInboxFacebook } = ThreeColumnRouteLinks;
	const urlPath = location.pathname;
	const urlPathSplit = urlPath.split('/');
	const hasAccessFor = LEFT_PANEL_ACCESS_ROUTES[path];

	const isBotAdminDataAvailable = leftPanelsV2Data.length;

	const alertAndRedirect = () => {
		// TODO : change alert to a popup component asking permission to make the redirection
		dispatch(redirectToLoginAction());

		return <></>;
	};

	const checkAccountSettingsAccess = () =>
		LEFT_PANEL_ACCESS_ROUTES[urlPath] === '' || leftPanelsV2Data.indexOf(LEFT_PANEL_ACCESS_ROUTES[urlPath]) > -1;

	const checkMessagingAccess = () => {
		if (!botRef) {
			return false;
		}
		if (!urlPathSplit[2]) {
			return true;
		}
		if (leftPanelsV2Data.indexOf(`conversation.${urlPathSplit[2]}`) > -1) {
			return true;
		}

		return false;
	};

	const checkLeftPanelAccess = () => {
		if (path.indexOf(socialInboxInstagram) > -1) {
			return isInstaInboxCommentsEnabled;
		}
		if (path.indexOf(ThreeColumnRouteLinks.paymentLogsLink) > -1) {
			return isWhatsAppConfigured && isWaPaymentEnabled;
		}

		if (path.indexOf(socialInboxFacebook) > -1) {
			return isFbInboxCommentsEnabled;
		}

		if (path.indexOf('/messages') > -1) {
			return checkMessagingAccess();
		}

		if (path.indexOf('/account-settings') > -1) {
			return checkAccountSettingsAccess();
		}

		if (path.includes(ThreeColumnRouteLinks.createBroadcastMessageTemplates)) {
			return isCreateMessageTemplatesV2Enabled;
		}

		if (path.includes(ThreeColumnRouteLinks.broadcastEmailTemplates)) {
			return isEmailTemplatesEnabled;
		}

		if (path.indexOf('/broadcast/templates') > -1) {
			return isDialog360Configured || isWhatsAppCloudConfigured || isCommonWhatsappEnabled;
		}

		if (path.indexOf(ThreeColumnRouteLinks.storeProductsLink) > -1) {
			return shopifyProductDiscoveryEntitlement;
		}

		if (path.indexOf(ThreeColumnRouteLinks.storeConversionTriggersLink) > -1) {
			return engagementTriggersEnabled;
		}

		if (path.indexOf(ThreeColumnRouteLinks.storeCustomersLink) > -1) {
			return isSystemSegmentsEnabled && !isAgent;
		}

		if (path.indexOf(ThreeColumnRouteLinks.analyticsConversionsLink) > -1) {
			return !isAgent && isShopifyEnabled;
		}

		if (path.indexOf(ThreeColumnRouteLinks.purchaseFunnelLink) > -1) {
			return !isAgent && isShopifyEnabled && !isAcceleratorEcom;
		}

		if (path.indexOf(ThreeColumnRouteLinks.productAnalyticsLink) > -1) {
			return !isAgent && isShopifyEnabled && !isAcceleratorEcom;
		}

		if (botRef && (leftPanelsV2Data.indexOf(hasAccessFor) > -1 || hasAccessFor === '')) {
			return true;
		}

		if (!botRef && COMMON_ROUTES.indexOf(path) > -1) {
			return true;
		}

		return false;
	};

	const checkPathAccess = () => {
		if (!isBotAdminDataAvailable) {
			return <Loader />;
		}

		if (checkLeftPanelAccess()) {
			return <>{children}</>;
		}

		if (ALL_ROUTES.indexOf(path) > -1) {
			navigate(noAccess);

			return <>{children}</>;
		}

		navigate(noPageFound);

		return <>{children}</>;
	};

	const checkTokenAvailability = () => (token && !csrfToken ? alertAndRedirect() : <Loader />);

	return token && csrfToken ? checkPathAccess() : checkTokenAvailability();
}
