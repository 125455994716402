import { ReactNode, useMemo, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Tab } from 'baseui/tabs-motion';
import { toaster } from 'baseui/toast';
import { MonoLabelSmall } from 'baseui/typography';
import { addMonths } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { conversationFormNameMap, conversationKeys } from 'components/ConversationFilter/consts';
import ConversationFilter from 'components/ConversationFilter/ConversationFilter';
import ENGTConditional from 'components/ENGTConditional/ENGTConditional';
import { CONDITIONS, TYPE_OPERATOR_MAPPINGS } from 'components/ENGTConditional/TypeOperatorMappings';
import ENGTErrorBoundary from 'components/ENGTErrorBoundary/ENGTErrorBoundary';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import Tabs from 'components/UI/ENGTTabs/ENGTTabs';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import Form from 'components/UI/Form/Form/Form';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import ModalWithSections from 'components/UI/Modal/ModalWithSections/ModalWithSections';

import { ENGATI_RESPONSE_CODE } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { adjustTimeByOffset, getEllipsisedText, getFixedTimeOffsetFromUTC, hexToRgb, isEmpty } from 'shared/helpers';
import { getErrorMsgForEvalExpression } from 'shared/helpers/quillHelper';
import { tagsNotAllowed } from 'shared/pathBuilderHelpers';
import { sendButtonClickEventToGA4 } from 'shared/trackingHelpers';

import { timezoneSelector } from 'store/App/User';
import { API } from 'store/App/User/api';

type ISelectFieldValue = [{ id: string; label: string }] | [];

interface ICustomFilterProps {
	attributes: Array<IObjProps>;
	channels: Array<any>; // provide array of configured channels
	isOpen: boolean;
	isReadOnly: boolean;
	description: string | ReactNode;
	heading: string | ReactNode;
	segment?: IObjProps;
	segmentType?: string;
	showAttributeFilter?: boolean;
	showConversationFilter?: boolean;
	type: string;
	tagsDetails?: {
		showTags: boolean;
		tagOptions: Array<{ id: string; label: string | React.ReactNode }>;
	};
	customSegment?: boolean;
	setCustomSegment?: Function;
	isUserFilterEditMode?: boolean;
	setIsUserFilterEditMode?: Function;

	onClose: (...args: any) => any;
	onApply: (...args: any) => any;
	gtmTrackingId?: string;
}

export interface IAttributesFormFiels {
	name?: string;
	expression?: string;
	customerSegmentDate?: Array<any>;
	segmentRules?: Array<ISegmentRules>;
	operators?: string;
}

export interface IConversataionFormFields {
	category_id: ISelectFieldValue;
	custom_date: any;
	last_active_at: ISelectFieldValue;
	livechat_engaged_by: Array<{ id: string }>;
	livechat_resolved_by: string;
	livechat_state: ISelectFieldValue;
	livechat_transffered_by: string;
	tags: ISelectFieldValue;
}

export type ICustomFilterForm = IAttributesFormFiels & IConversataionFormFields;

export interface ISegmentRules {
	lhOperand: any;
	operator: any;
	rhOperand: any;
	operatorList: Array<any>;
}

const operandMap: any = {
	customDate: 'CUSTOM_RANGE',
	days: 'LAST_X_DAYS',
	hours: 'LAST_X_HOURS',
	inputField: 'IS_EQUAL_TO',
};
enum SEGMENT_TYPE {
	CUSTOMER = 'CUSTOMER',
}
const dateOperandMap: any = {
	24: operandMap.hours,
	30: operandMap.days,
	60: operandMap.days,
	90: operandMap.days,
	custom_date: operandMap.customDate,
};

const CustomFilters = (props: ICustomFilterProps) => {
	const {
		attributes = [],
		tagsDetails = {
			showTags: false,
			tagOptions: [],
		},
		channels = [],
		description,
		heading,
		segmentType,
		customSegment,
		setCustomSegment,
		isReadOnly,
		isOpen,
		segment = {},
		showAttributeFilter = true,
		showConversationFilter = false,
		type = '',
		isUserFilterEditMode,
		setIsUserFilterEditMode,
		onClose,
		onApply,
		gtmTrackingId,
	} = props;
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['components']);

	const customFilterTabs: any = {
		attributes: t('components:customFilters.tabs.Attributes'),
		conversation: t('components:customFilters.tabs.Conversation'),
	};
	const MILLISECONDSINADAY = 86400000;

	const [activeTab, setActiveTab] = useState<string>(customFilterTabs['attributes']);
	const [showLoader, setLoader] = useState<boolean>(false);
	const tempDate: Date = new Date();
	const endDate: Date = new Date();
	const startDate: Date = new Date(tempDate.setMonth(tempDate.getMonth() - 1));
	const [dateSelected, setDateSelected] = useState<any>([startDate, endDate]);
	const filterContainerRef = useRef<HTMLDivElement | null>(null);
	const isConversationFilter = activeTab === customFilterTabs.conversation;
	const validSegmentNameRegex = /^[a-zA-Z0-9_\. ]+$/;

	const portalTimezone = useSelector(timezoneSelector);

	const conversationDetails = useMemo(
		() =>
			Object.keys(segment?.rules || {})
				?.filter((d) => d.includes('C'))
				?.map((d) => segment.rules[d]),
		[segment]
	);
	const timerangeToDays = useMemo(() => {
		const days =
			(Date.UTC(dateSelected[1]?.getFullYear(), dateSelected[1]?.getMonth(), dateSelected[1]?.getDate()) -
				Date.UTC(dateSelected[0]?.getFullYear(), dateSelected[0]?.getMonth(), dateSelected[0]?.getDate())) /
			MILLISECONDSINADAY;

		return days + 1;
	}, [dateSelected]);

	const getDefaultSegmentRules = () => {
		if (!isEmpty(segment.rules) && attributes?.length) {
			const attributeIdTypeMap: IObjProps = {};
			attributes.forEach((attribute: any) => (attributeIdTypeMap[attribute.id] = attribute.type));
			const rules: Array<ISegmentRules> = Object.keys(segment.rules)
				.filter((key) => key[0] === 'A')
				.map((key) => {
					const operatorList = TYPE_OPERATOR_MAPPINGS()[attributeIdTypeMap[segment.rules[key].lhOperand]];
					const selectedOperator = operatorList.filter((op: any) => op.id === segment.rules[key].operator);

					return {
						lhOperand: [
							{
								id: segment.rules[key].lhOperand,
								type: attributeIdTypeMap[segment.rules[key].lhOperand],
							},
						],
						operator: selectedOperator,
						rhOperand: segment.rules[key].rhOperand,
						operatorList,
					};
				});

			return rules;
		}

		return [
			{
				operator: '',
				lhOperand: '',
				rhOperand: '',
				operatorList: [],
			},
		];
	};

	const defaultValues: ICustomFilterForm = {
		name: segment?.segmentName || '',
		customerSegmentDate: segment?.dateSelected || dateSelected || '',
		expression: segment?.expression || '',
		segmentRules: getDefaultSegmentRules(),
		livechat_state: [],
		category_id: [],
		last_active_at: [],
		custom_date: [],
		livechat_engaged_by: [{ id: '' }],
		livechat_resolved_by: '',
		livechat_transffered_by: '',
		tags: [],
	};

	const methods = useForm<ICustomFilterForm>({
		mode: 'onSubmit',
		defaultValues,
		shouldUnregister: false,
	});

	const {
		handleSubmit,
		getValues,
		trigger,
		control,
		formState: { dirtyFields, isDirty, errors },
	} = methods;

	const switchTab = (tab: string) => {
		setActiveTab(tab);
	};

	const formatSegmentRules = (rules?: Array<IObjProps>) => {
		const formattedRules: IObjProps = {};

		rules
			?.filter((rule) => rule.operator.length > 0)
			.forEach((rule, index) => {
				const { inNextXdays, beforeXdays, lastXdays, isEmpty, isNotEmpty } = CONDITIONS();
				const isOperatorInList = [
					inNextXdays.id,
					beforeXdays.id,
					lastXdays.id,
					isEmpty.id,
					isNotEmpty.id,
				].includes(rule.operator[0].id);
				const isDateField: boolean = rule.lhOperand[0].type === 'DATE' && !isOperatorInList;

				let rhOperand = isDateField
					? rule?.rhOperand !== ''
						? new Date(rule?.rhOperand)
						: ''
					: rule.rhOperand || '';

				if (isDateField) {
					const month = `0${rhOperand.getMonth() + 1}`.slice(-2);
					const day = `0${rhOperand.getDate()}`.slice(-2);
					const fullDate = [rhOperand.getFullYear(), month, day].join('-');
					rhOperand = new Date(fullDate).toISOString();
					const offSetTime = getFixedTimeOffsetFromUTC(portalTimezone);
					if (offSetTime) {
						rhOperand = adjustTimeByOffset(rhOperand, offSetTime);
					}
				}

				formattedRules[`A${index + 1}`] = {
					operator: rule.operator[0].id,
					lhOperand: rule.lhOperand[0].id,
					rhOperand,
				};
			});

		return formattedRules;
	};
	const isCustomerSegmentType = () => segmentType === SEGMENT_TYPE.CUSTOMER;
	const isEditSegmentMode = () => heading === t('pages:users.segments.edit.heading');

	const getFormattedDate = (date = new Date()) => date.toISOString();

	const getRHOperand = (field: any, value: any) => {
		switch (field) {
			case 'last_active_at':
				return value === 'custom_date'
					? [
							getFormattedDate(getValues('custom_date')[0]),
							getFormattedDate(getValues('custom_date')[1]),
						].join(',')
					: `${value}`;

			case 'livechat_engaged_by':
				const engagedBy: any = [];

				getValues('livechat_engaged_by').forEach((agent: any) => {
					if (agent.id[0] && agent.id[0].id) {
						engagedBy.push(agent.id[0].id);
					}
				});

				return engagedBy.join(' OR ');

			case 'tags':
				const formattedTags: any = [];
				value.forEach((tag: any) => {
					if (tag.label) {
						formattedTags.push(tag.label);
					}
				});

				return formattedTags.join('" OR "');

			default:
				return `${value}`;
		}
	};

	const createRules = (data: any) => {
		const rules: any = [];
		const rulesMap: any = {};
		const filterExpression: any = [];

		Object.keys(data).forEach((field: any) => {
			if (conversationKeys.indexOf(field) > -1) {
				const value: any = data[field]?.length
					? field === conversationFormNameMap.tagId
						? data[field]
						: data[field][0].id
					: typeof data[field] === 'string'
						? data[field]
						: '';

				if (field !== 'custom_date') {
					if (value) {
						rules.push({
							lhOperand: field,
							operator: field === 'last_active_at' ? dateOperandMap[value] : operandMap.inputField,
							rhOperand: getRHOperand(field, value),
						});
					}
				}
			}
		});

		rules.forEach((rule: any, index: number) => {
			const expressionName = `C${index + 1}`;

			rulesMap[expressionName] = rule;
			filterExpression.push(expressionName);
		});

		return { rules: rulesMap, conversationExpressions: filterExpression.join(' AND ') };
	};

	const extractLhOperands = (rules: IObjProps) => {
		const lhOperands = Object.values(rules)
			.map((rule) => rule.lhOperand)
			.join(', ');

		return lhOperands;
	};

	const sendCreateSegmentEventToGA4 = (allRules: IObjProps) =>
		sendButtonClickEventToGA4({
			gtmTrackingId: gtmTrackingId ?? '',
			segmentParametersSelected: extractLhOperands(allRules),
		});

	const onSave = (data: ICustomFilterForm) => {
		const { rules: conversationalRules = {}, conversationExpressions } = createRules(data);
		const attributeRules: any = formatSegmentRules(data.segmentRules) || {};
		const allRules = { ...attributeRules, ...conversationalRules };
		const segmentName = data.name || null;
		const expressions = [];
		data.expression && !isEmpty(attributeRules) && expressions.push(data.expression);
		conversationExpressions && expressions.push(conversationExpressions);

		const params: any = {
			id: segment.id,
			rules: allRules,
			filterExpression: expressions.join(' AND '),
			segmentName,
			dateSelected,
			type,
			target_url: isCustomerSegmentType() ? 'addUpdateShopifyCustomerSegment' : 'addEditSegment',
		};
		if (isReadOnly) {
			delete params.id;
		}

		if (segmentName && !validSegmentNameRegex.test(segmentName)) {
			toaster.negative(
				<ENGTToasterContainer title={t('common:failed')} description={t('errors:symbolsNotAllowed')} />,
				{}
			);

			return;
		}

		gtmTrackingId && sendCreateSegmentEventToGA4(allRules);

		setLoader(true);
		API.generateCustomFilter(params)
			.then((response: any) => {
				if (response.data && response.data.response_obj) {
					const responseObject = response.data.response_obj;
					if (responseObject?.status?.code === 1000 && responseObject?.responseObject) {
						let { inProgressRules } = responseObject.responseObject;
						if (inProgressRules?.length > 0) {
							const length = inProgressRules?.length;
							inProgressRules = inProgressRules.map((element: string, index: number) =>
								getEllipsisedText(element, 20)
							);
							if (length === 1) {
								toaster.positive(
									<ENGTToasterContainer
										title={t('common:success')}
										description={t('components:customFilters.toaster.segmentInProgressSingular', {
											inProgressRules: inProgressRules.toString(),
										})}
									/>,
									{}
								);
							} else {
								toaster.positive(
									<ENGTToasterContainer
										title={t('common:success')}
										description={t('components:customFilters.toaster.segmentInProgressPlural', {
											inProgressRules: inProgressRules.toString(),
										})}
									/>,
									{}
								);
							}
						}
						toaster.positive(
							<ENGTToasterContainer
								title={t('common:success')}
								description={t('components:customFilters.toaster.segmentationInProgress')}
							/>,
							{}
						);
						onApply(responseObject.responseObject);
						onClose();
					} else if (responseObject?.status?.code === 8013) {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:failed')}
								description={t('components:customFilters.toaster.segmentNameExists')}
							/>,
							{}
						);
					} else if (responseObject?.status?.code === ENGATI_RESPONSE_CODE.STATUS_15005) {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:failed')}
								description={t('components:customFilters.toaster.attributeLimitFilter')}
							/>,
							{}
						);
					} else {
						toaster.negative(
							<ENGTToasterContainer title={t('common:failed')} description={t('common:wentWrong')} />,
							{}
						);
					}
				}
				setLoader(false);
			})
			.catch(() => {
				setLoader(false);
			});
	};

	const renderDescription = (
		<MonoLabelSmall color={hexToRgb(theme.colors.accent50, 0.8)} marginBottom='2rem'>
			{description}
		</MonoLabelSmall>
	);

	const renderSegment = () => (
		<>
			<Controller
				control={control}
				name='name'
				rules={{
					required: !isReadOnly ? (t('errors:requiredErrorMessage') as string) : false,
					pattern: {
						value: new RegExp(validSegmentNameRegex),
						message: t('errors:symbolsNotAllowed'),
					},
				}}
				render={({ ref, value, onChange }) =>
					!isReadOnly ? (
						<FormInput
							name='name'
							width='100%'
							disabled={isCustomerSegmentType() && isEditSegmentMode()}
							label={t('pages:users.segments.create.name')}
							placeholder={t('pages:users.segments.create.placeholders.name')}
							onChange={onChange}
							value={value}
							inputRef={ref}
						/>
					) : (
						<></>
					)
				}
			/>
			{isCustomerSegmentType() && (
				<Controller
					control={control}
					name='customerSegmentDate'
					rules={{
						validate: (value) => {
							if (!(value && value[0] && value[1])) {
								return t('errors:requiredErrorMessage').toString();
							}

							return true;
						},
					}}
					render={({ ref, onChange, value }) => (
						<>
							<FormLabel
								id={t('pages:users.segments.create.setDate')}
								label={t('pages:users.segments.create.setDate')}
								className={{ textAlign: 'left' }}
								tooltip={t('pages:users.segments.create.tooltipSetDate')}
							/>
							<Block
								className={css({
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'left',
									width: '100%',
									justifyContent: 'space-between',
									marginBottom: '1rem',
								})}
							>
								<DefaultDatePicker
									name='customerSegmentDate'
									value={value ? new Date(value[0]) : dateSelected[0]}
									startDate={value?.[0] ? new Date(value?.[0]) : dateSelected[0]}
									endDate={value?.[1] ? new Date(value?.[1]) : dateSelected[1]}
									isPickerRanged
									onChange={(dates: any) => {
										setDateSelected(dates);
										onChange(dates);
									}}
									inputWidth='90%'
									height='1.80rem'
									maxDate={new Date()}
									minDate={addMonths(new Date(), -12)}
									inputRef={ref}
									error={errors?.customerSegmentDate}
								/>
							</Block>
						</>
					)}
				/>
			)}
		</>
	);

	const renderTabs = () =>
		showAttributeFilter && showConversationFilter ? (
			<Tabs
				activeTab={activeTab}
				onChange={switchTab}
				renderAll
				tabOverrides={{
					Tab: {
						style: {
							fontSize: '0.875rem',
							lineHeight: '1rem',
							width: '100%',
							background: 'transparent',
						},
					},
				}}
			>
				<Tab title={customFilterTabs['attributes']} key={customFilterTabs['attributes']}>
					<ENGTConditional
						channels={channels}
						details={segment}
						leftOperands={attributes}
						overrides={{
							width: '100%',
						}}
						customSegment={customSegment}
						setCustomSegment={setCustomSegment}
						isUserFilterEditMode={isUserFilterEditMode}
						setIsUserFilterEditMode={setIsUserFilterEditMode}
					/>
				</Tab>
				<Tab title={customFilterTabs['conversation']} key={customFilterTabs['conversation']}>
					<ConversationFilter conversationDetails={conversationDetails} tagDetails={tagsDetails} />
				</Tab>
			</Tabs>
		) : showAttributeFilter ? (
			<ENGTConditional
				channels={channels}
				segmentType={segmentType}
				details={segment}
				leftOperands={attributes}
				isEditMode={isEditSegmentMode()}
				timerangeDays={isCustomerSegmentType() && timerangeToDays ? timerangeToDays : undefined}
				overrides={{
					width: '100%',
				}}
			/>
		) : (
			<ConversationFilter conversationDetails={conversationDetails} tagDetails={tagsDetails} />
		);

	const customFilters = () => (
		<div
			ref={filterContainerRef}
			className={css({ textAlign: 'center', paddingLeft: '3.75rem', paddingRight: '3.75rem' })}
		>
			{renderDescription}
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSave)}>
					{renderSegment()}
					{renderTabs()}
				</Form>
			</FormProvider>
		</div>
	);

	const executeScroll = () =>
		filterContainerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

	const validateRulesets = (ruleSet: any, displayExpression: any) => {
		const checkExpression = tagsNotAllowed(t, displayExpression, true);
		if (!checkExpression) {
			return false;
		}

		for (let i = 0; i < ruleSet.length; i++) {
			if (
				(['IS_EMPTY', 'IS_NOT_EMPTY'].indexOf(ruleSet[i]?.operator?.[0]?.id) === -1 && !ruleSet[i].rhOperand) ||
				!ruleSet[i].lhOperand ||
				!ruleSet[i].operator
			) {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:incomplete')} />,
					{}
				);

				return false;
			}

			if (ruleSet[i].rhOperand?.length >= 255) {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:invalid')} />,
					{}
				);

				return false;
			}

			const checkrhOperand = tagsNotAllowed(t, ruleSet[i].rhOperand, false);
			if (!checkrhOperand) {
				return false;
			}

			const expressionCheck = getErrorMsgForEvalExpression(
				displayExpression,
				ruleSet.map((d: any, i: number) => ({ name: isCustomerSegmentType() ? `P${i + 1}` : `A${i + 1}` }))
			);
			if (expressionCheck) {
				toaster.negative(<ENGTToasterContainer title={t('common:error')} description={expressionCheck} />, {});

				return false;
			}
		}

		return true;
	};
	const fieldChangeForFormSubmit = () =>
		dirtyFields.segmentRules || dirtyFields.name || dirtyFields.customerSegmentDate || dirtyFields.operators;

	return (
		<ModalWithSections
			heading={heading}
			confirmBtnLabel={isCustomerSegmentType() && !isEditSegmentMode() ? t('common:create') : t('common:save')}
			isLoading={showLoader}
			isConfirmBtnDisabled={!isDirty || showLoader || Object.keys(dirtyFields).length < 1}
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={async () => {
				let validation: any = null;
				if (fieldChangeForFormSubmit()) {
					validation = await trigger();
				}
				if (isConversationFilter) {
					if ((dirtyFields.segmentRules && validation) || !dirtyFields.segmentRules) {
						onSave(getValues());
					} else {
						switchTab(customFilterTabs['attributes']);
					}
				} else if (validation) {
					if (validateRulesets(getValues('segmentRules'), getValues('expression'))) {
						onSave(getValues());
					} else {
						return false;
					}
				} else {
					executeScroll();
				}
			}}
			submitType='submit'
			width='45rem'
		>
			{customFilters()}
		</ModalWithSections>
	);
};

const CustomFilter = (props: ICustomFilterProps) => {
	const { t } = useTranslation(['errors']);

	return (
		<ENGTErrorBoundary message={t('errors:errorBoundary.customFilter')} type='modal' onClose={props.onClose}>
			<CustomFilters {...props} />
		</ENGTErrorBoundary>
	);
};
export default CustomFilter;
