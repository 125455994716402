import {
	ATTRIBUTE_LIST_MAX_LENGTH,
	GLOBAL_ZOOM_SCALE,
	templateMessageAllowedProviders,
	waNodesAllowedProviders,
	waNodesAllowedProvidersForWAFlowNode,
} from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { ENTITLEMENTS } from 'shared/enum';
import { isEmpty } from 'shared/helpers';

import { builderState, IBuilderStateInterface, IFlowDataInterface } from './state';

export const builderReducer = {
	fetchEntitlements: (
		state: IBuilderStateInterface,
		action: { payload: { botConfig: IObjProps; botData: IObjProps } }
	) => {
		const data = action.payload.botConfig;
		const selectable: any = [
			'enable_advanced_script_node',
			'enable_campaign_node',
			'enable_card_node',
			'enable_conversation_status_node',
			'enable_eval_node',
			'enable_feedback_node',
			'enable_file_upload',
			'enable_freshdesk_node',
			'enable_form_node',
			'enable_google_calendar_node',
			'enable_google_sheets_node',
			'enable_identity_node',
			'enable_pause_node',
			'enable_salesforce_node',
			'enable_scripting_node',
			'enable_shopify_shopper_assitance',
			'enable_slider_node',
			'enable_sms_node',
			'enable_webview_node',
			'enable_zapier_node',
			'enable_zapier_integration',
			'enable_zendesk_node',
			'multi_language_enabled',
			ENTITLEMENTS.ENABLE_TEMPLATE_MESSAGE,
			ENTITLEMENTS.ENABLE_WHATSAPP_COMMECE,
			ENTITLEMENTS.IS_WA_COMMERCE_CONFIGURED,
			ENTITLEMENTS.ENABLE_RECURRING_NOTIFICATION_NODE,
			'enable_wa_payment',
			'enable_wa_flows',
			'enable_faq_filter_node',
			'enable_send_email_node',
			'enable_adv_json_api',
			'enable_gorgias_node',
			'enable_smart_assist',
		];
		const getKeysFromObject = (obj: any, keys: any) => keys.reduce((a: any, b: any) => ((a[b] = obj[b]), a), {});
		const res = getKeysFromObject(data, selectable);
		res['multi_language_enabled'] = res['multi_language_enabled'] === 'ENABLED';
		res['enable_smart_assist'] = res['enable_smart_assist'] !== 'NA';
		res[ENTITLEMENTS.ENABLE_TEMPLATE_MESSAGE] = templateMessageAllowedProviders.includes(
			action.payload.botData?.provider_configured
		);
		res[ENTITLEMENTS.ENABLE_WHATSAPP_COMMECE] =
			res[ENTITLEMENTS.ENABLE_WHATSAPP_COMMECE] &&
			waNodesAllowedProviders.includes(action.payload.botData?.provider_configured) &&
			res[ENTITLEMENTS.IS_WA_COMMERCE_CONFIGURED];

		res[ENTITLEMENTS.ENABLE_RECURRING_NOTIFICATION_NODE] = action.payload.botData.is_facebook_configured;
		res['enable_wa_payment'] =
			res['enable_wa_payment'] &&
			waNodesAllowedProvidersForWAFlowNode.includes(action.payload.botData?.provider_configured);
		res['enable_wa_flows'] =
			res['enable_wa_flows'] &&
			waNodesAllowedProvidersForWAFlowNode.includes(action.payload.botData?.provider_configured);
		state.nodeEntitlements = res;
	},
	getAllFlows: (state: IBuilderStateInterface, action: { payload: Array<IObjProps> }) => {
		state.flows.data = action.payload;
		state.flows.isLoading = false;
		state.flows.isError = false;
	},
	getAllFlowsError: (state: IBuilderStateInterface) => {
		state.flows.data = [];
		state.flows.isLoading = false;
		state.flows.isError = true;
	},
	getFlowAttributes: (state: IBuilderStateInterface, action: { payload: Array<IObjProps> }) => {
		state.flowAttributes = action.payload;
	},
	getAllAttributes: (state: IBuilderStateInterface, action: { payload: Array<IObjProps> }) => {
		state.allAttributes = action.payload;
	},
	getFlowData: (state: IBuilderStateInterface, action: { payload: IFlowDataInterface }) => {
		state.flowData.data = action.payload;
		state.flowData.isLoading = false;
		state.flowData.isError = false;
	},
	getFlowDataError: (state: IBuilderStateInterface) => {
		state.flowData.data = {
			path_key: '',
			nodes: [],
			display_name: '',
			settings_required: false,
			available_for_livechat: false,
			root_node: '',
		};
		state.flowData.isLoading = false;
		state.flowData.isError = true;
	},
	updateFlowDataNodes: (state: IBuilderStateInterface, action: { payload: Array<IObjProps> }) => {
		state.flowData.data.nodes = action.payload;
	},
	setFlowDataNodeCoordinates: (state: IBuilderStateInterface, action: { payload: any }) => {
		const { id, xCoordinate, yCoordinate } = action.payload;
		let indexToUpdate = 0;
		const { nodes } = state.flowData.data;

		nodes.forEach((node: any, index: number) => {
			if (node.node_key === id) {
				indexToUpdate = index;
			}
		});

		state.flowData.data.nodes[indexToUpdate].coordinates = {
			xCoordinate,
			yCoordinate,
			height: 140,
		};
	},
	setFlowDataNodeName: (state: IBuilderStateInterface, action: { payload: any }) => {
		const { newName } = action.payload;
		state.selectedNode.node_name = newName;
	},
	getNodesByQuery: (state: IBuilderStateInterface, action: { payload: any }) => {
		const { data, search, query } = action.payload;

		state.nodesList = {
			data,
			loading: false,
			search,
			query,
		};
	},
	getPathVariables: (state: IBuilderStateInterface, action: { payload: IObjProps }) => {
		state.pathVariables = action.payload;
	},
	setAllFlowsLoader: (state: IBuilderStateInterface) => {
		state.flows.isLoading = true;
		state.flows.isError = false;
	},
	setNodesAttrList: (state: IBuilderStateInterface, action: { payload: any }) => {
		const pathAttributes = action.payload.responseObject;
		const maxLength = ATTRIBUTE_LIST_MAX_LENGTH;
		state.pathAttributeToCopyLists = [];
		state.nodesAttrList.data = {};
		state.nodesAttrList.lastNodeToRender = '';
		if (pathAttributes && !isEmpty(pathAttributes)) {
			let index = 0;
			for (const key in pathAttributes) {
				let nodeAttributeList = '';

				const nodeAttributes = pathAttributes[key];
				nodeAttributeList = String(nodeAttributes).split(',').join(', ');
				if (nodeAttributeList.length > maxLength) {
					nodeAttributeList = `${String(nodeAttributeList).substring(0, maxLength + 1)}...`;
				}
				state.nodesAttrList.data[key] = nodeAttributeList;
				if (index === 0) {
					state.nodesAttrList.lastNodeToRender = key;
				}
				index++;
			}
		}
	},
	setPathsForAgent: (state: IBuilderStateInterface, action: { payload: any }) => {
		state.pathForAgents.data = action.payload;
		state.pathForAgents.isLoading = false;
	},
	setFlowDataLoader: (state: IBuilderStateInterface) => {
		state.flowData.isLoading = true;
		state.flowData.isError = false;
	},
	setMarkUnmarkPathForAgent: (state: IBuilderStateInterface, action: { payload: string }) => {
		const key = action.payload;
		state.pathForAgents.data[key] = !state.pathForAgents.data[key];
	},
	setSelectedNodeDetails: (state: IBuilderStateInterface, action: { payload: IObjProps }) => {
		state.selectedNode = action.payload;
	},
	setHighlightedNodeDetails: (state: IBuilderStateInterface, action: { payload: IObjProps }) => {
		state.highlightedNode = action.payload;
	},

	getFlowNodeCoordinateDataError: (state: IBuilderStateInterface) => {
		state.flowData.data = {
			path_key: '',
			nodes: [],
			display_name: '',
			settings_required: false,
			available_for_livechat: false,
			root_node: '',
		};
		state.flowData.isLoading = false;
		state.flowData.isError = true;
	},
	addNode: (state: IBuilderStateInterface, action: { payload: any }) => {
		console.log(state);
		console.log(action);
		// let { data, search, query } = action.payload;
		// state.userPathNodesList = {
		// 	data: data,
		// 	loading: false,
		// 	search: search,
		// 	query: query,
		// };
		// state.userPathNodesList.data.nodes.push();
	},
	updateCopyAttrList: (state: IBuilderStateInterface, action: { payload: IObjProps }) => {
		const attrList = state.pathAttributeToCopyLists;
		const { allAttributes, nodeKey, type } = action.payload;
		if (type === 'add') {
			const newAttrList = [...attrList, ...nodeKey];

			return {
				...state,
				pathAttributeToCopyLists: newAttrList,
			};
		}
		if (type === 'reset') {
			return {
				...state,
				pathAttributeToCopyLists: [],
			};
		}
		if (type === 'all') {
			return {
				...state,
				pathAttributeToCopyLists: allAttributes,
			};
		}
	},
	updateIsFormSubmittingState: (state: IBuilderStateInterface, action: { payload: any }) => {
		state.isFormSubmitting = action.payload;
	},
	updateSelectedNodeName: (state: IBuilderStateInterface, action: { payload: string }) => {
		state.selectedNode.node_name = action.payload;
	},
	setBotRef: (state: IBuilderStateInterface, action: { payload: string }) => {
		state.botRef = action.payload;
	},
	resetAllPathBuilderData: (state: IBuilderStateInterface) => {
		Object.keys(builderState).forEach((key) => {
			if (key !== GLOBAL_ZOOM_SCALE) {
				(state as IObjProps)[key] = (builderState as IObjProps)[key];
			}
		});
	},
	setExternalIntegrationKeys: (state: IBuilderStateInterface, action: { payload: any }) => {
		state.externalIntegrationKeys = action.payload;
	},
	setExternalIntegrations: (state: IBuilderStateInterface, action: { payload: any }) => {
		state.externalIntegrations = action.payload;
	},
	setConnectedUsers: (state: IBuilderStateInterface, action: { payload: any }) => {
		state.connectedUsers = action.payload;
	},
	setZoomScale: (state: IBuilderStateInterface, action: { payload: any }) => {
		state.globalZoomScale = action.payload;
	},
};
